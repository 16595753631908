import React from "react";
import ReactTooltip from "react-tooltip";
import { findDOMNode } from "react-dom";
import autobind from "react-autobind";
import cn from "classnames";
import _ from "lodash";
import { nanoid } from "nanoid";
import { DateTime } from "luxon";
import { DndProvider, Preview, TouchTransition } from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import filesize from "filesize";
import ReconnectingWebSocket from "reconnecting-websocket";

import logo from "./logo-rounded.png";
import logoTransparent from "./logo-transparent.png";
import TextFill from "./TextFill";
import Weeks from "./Weeks";
import { version } from "../package.json";
import notes from "./release-notes.json";
import "./Board.scss";
import DraggableCard from "./DraggableCard";
import Card from "./Card";
import Slot from "./Slot";
import CardHolder from "./CardHolder";
import CardTypes from "./CardTypes";
import CardPicker from "./CardPicker";
import { LOCAL_SETTINGS, ONLINE_SETTINGS } from "./defaults";

const isApp = !!window.cordova;
const randomColor = () =>
  "#" + Math.floor(Math.random() * 16777215).toString(16);

const releaseNotes = () => (
  <div className="release-notes">
    {Object.entries(notes).map(([version, { date, changes }]) => (
      <div key={version}>
        <div className="header">
          <strong>{version}</strong>
          <span>{date}</span>
        </div>
        <ul>
          {changes.map((done, idx) => (
            <li key={idx}>{done}</li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);

const elementInViewport = (el) => {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  );
};

const Touch = {
  backends: [
    {
      id: "touch",
      backend: TouchBackend,
      options: {
        enableMouseEvents: true,
        touchSlop: 25,
      },
      transition: TouchTransition,
      preview: true,
    },
  ],
};

const now = DateTime.now();
const params = new URLSearchParams(window.location.search);
const weekYear = parseInt(params.get("year")) || now.weekYear;
const weekNumber = parseInt(params.get("week")) || now.weekNumber;
const date = DateTime.fromObject({
  weekday: 1,
  weekYear,
  weekNumber,
});

const toMonday = (date) =>
  DateTime.fromObject({
    weekday: 1,
    weekYear: date.weekYear,
    weekNumber: date.weekNumber,
  });

class Board extends React.Component {
  state = {
    log: [],
    occupied: [],
    appCardSize: 150,
    connections: [],
    loading: true,
    online: false,
    active: null,
    updating: [],
    date,
    pincode: "",
    loggedIn: false,
    selected: {},
    local: { ...LOCAL_SETTINGS },
    board: {
      cards: [],
      settings: { ...ONLINE_SETTINGS },
      role: "reader",
    },
  };

  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount = () => {
    const { directory } = this.props;
    this.setState({ pincode: sessionStorage.getItem(directory) || "" });

    try {
      const local = JSON.parse(localStorage.getItem(directory));
      if (local && typeof local === "object") {
        this.setState({ local });
      }
    } catch (err) {}

    const isSecure = window.location.protocol === 'https:'
    const host =
      window.location.hostname === "localhost" ||
      window.location.hostname === "192.168.2.100"
        ? "ws://192.168.2.100:8080" :
        `${isSecure ? 'wss' : 'ws'}://ws.${window.location.host}`


    this.client = new ReconnectingWebSocket(host);
    this.client.addEventListener("close", () =>
      this.setState({ online: false })
    );
    this.client.addEventListener("open", () => {
      this.setState({ loading: false, online: true });

      if (this.state.loggedIn) {
        // relogin after dc
        this.checkPincode();
      }
    });
    this.client.addEventListener("message", (message) =>
      this.message(JSON.parse(message.data))
    );
    window.addEventListener("keydown", this.handleKeyPresses);
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("orientationchange", this.handleResize);

    const isInElement = (el, clientX, clientY) => {
      const { height, width, x, y } = el.getBoundingClientRect();
      return (
        clientX > x &&
        clientX < x + width &&
        clientY > y &&
        clientY < y + height
      );
    };
    const goNextOrPrevOnEnter = (e) => {
      const [{ clientX, clientY }] = e.touches;
      this._goPrev = isInElement(
        document.querySelector(".prev-day"),
        clientX,
        clientY
      );
      this._goNext = isInElement(
        document.querySelector(".next-day"),
        clientX,
        clientY
      );
    };

    setInterval(() => {
      if (!this.state.isDragging) return;
      if (this._goNext) {
        this.nextDay();
      } else if (this._goPrev) {
        this.prevDay();
      }
    }, 1000);

    if (isApp) {
      window.addEventListener("touchmove", goNextOrPrevOnEnter);
    }

    setInterval(() => {
      const { date } = this.state;
      const params = new URLSearchParams(window.location.search);
      const now = toMonday(DateTime.now());
      if (
        !now.hasSame(date, "day") &&
        !params.get("year") &&
        !params.get("week")
      ) {
        this.setState({ date: now }, () => this.scrollToToday());
      }
    }, 60 * 1000);
  };

  componentWillUnmount() {
    this.client.close();
    window.removeEventListener("keydown", this.handleKeyPresses);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("orientationchange", this.handleResize);
  }

  static getDerivedStateFromError(error) {
    return { hasJSError: true };
  }

  componentDidCatch(JSError, JSErrorInfo) {
    this.setState({ JSError, JSErrorInfo });
  }

  send = (type, data = {}) => {
    data.directory = this.props.directory;
    this.client.send(JSON.stringify({ type, data }));
  };

  _occupied = [];

  released = () => {
    this.send("RELEASED", { ids: this._occupied });
    this._occupied = [];
  };

  occupied = (...ids) => {
    this._occupied = ids;
    this.send("OCCUPIED", { ids });
  };

  getLog = () => {
    const { pincode } = this.state;
    this.send("READ-LOG", { pincode });
  };

  writeLog = (action) => {
    console.log(action);
    this.send("WRITE-LOG", { action });
  };

  logCardTitle = (card, title = true) => {
    if (!card.slot) {
      return title ? card.title.join(" ") : "";
    }
    const [col, row] = this.getSlotDescription(card.slot);
    if (title) {
      return `${card.title.join(" ")} | ${col || ""} | ${row || ""}`;
    }
    return `${col || ""} | ${row || ""}`;
  };

  toggleLatencyCheck = () => {
    if (this._checkPing) {
      clearInterval(this._checkPing);
      this._checkPing = null;
      this.setState({ latency: undefined });
    } else {
      const checkLatency = () => {
        this._ping = new Date();
        this.send("PING");
      };
      checkLatency();
      this._checkPing = setInterval(checkLatency, 1000);
    }
  };

  message = ({ type, data }) => {
    const { board, updating, popup } = this.state;
    switch (type) {
      case "PONG":
        this.setState({ latency: new Date() - this._ping });
        break;
      case "OCCUPIED":
        this.setState({ occupied: data });
        break;
      case "CONNECTIONS":
        this.setState({ connections: data });
        break;
      case "READ-LOG":
        this.setState({ log: data });
        break;
      case "PINCODE":
        if (data === "incorrect") {
          this.setState({ error: "Pincode klopt niet" });
        } else {
          if (data.settings) {
            data.settings.cardHolders = data.settings.cardHolders || [];
            data.settings.cardTypes = data.settings.cardTypes || [];
          }

          // Filter for invalid cards
          data.cards = data.cards.filter((card) => {
            try {
              this.unpack(card.slot);
            } catch (e) {
              return false;
            }
            return true;
          });

          this.setState({ loggedIn: true, board: { ...board, ...data } });
          sessionStorage.setItem(this.props.directory, this.state.pincode);

          if (!data.settings) {
            this.saveSettings();
          }
        }
        break;
      case "FULL_SAVE":
        board.cards = data;
        this.setState({ board });
        break;
      case "SAVE":
        switch (data.action) {
          case "ALL":
            this.setState({ board: data.board });
            break;
          case "SWAP":
            this.doSwap(data.cards[0], data.cards[1]);
            break;
          case "UPDATE":
            data.cards.forEach((card) => {
              const index = this.getCardIndex(card.id);
              if (index !== -1) {
                board.cards[index] = card;
              } else {
                board.cards.push(card);
              }
              data.cards.forEach((cardId) =>
                _.pullAt(updating, updating.indexOf(card.id))
              );
            });
            break;
          case "DELETE":
            _.pullAt(
              board.cards,
              data.cards.map((id) => this.getCardIndex(id))
            );
            data.cards.forEach((cardId) =>
              _.pullAt(updating, updating.indexOf(cardId))
            );
            break;
          default:
            break;
        }
        this.setState({ board, updating });
        break;
      case "IMPORT":
        this.logout(false);
        window.alert("U bent uitgelogd omdat er een backup is terug gezet.");
        break;
      case "UPDATE-SETTINGS":
        board.settings = data;
        board.settings.cardHolders = board.settings.cardHolders || [];
        board.settings.cardTypes = board.settings.cardTypes || [];
        this.setState({ board });
        break;
      case "UPDATE-CARD-TYPES":
        board.settings.cardTypes = data.cardTypes;
        board.settings.cardHolders = data.cardHolders;
        this.setState({ board });
        break;
      case "DOWNLOAD-BACKUP":
        this.download(
          data.fileName,
          new Blob([data.file], { type: "application/json" })
        );
        break;
      case "CLEANED":
        window.alert("Opgeschoond");
        board.cards = data.cards;
        this.setState({ board });
        break;
      case "BACKUP-ADD":
      case "BACKUP-CHANGE":
      case "BACKUP-REMOVE":
        this.setState({ board: { ...board, backups: data } });
        break;
      default:
        break;
    }
  };

  scrollToToday = () => {
    const { today } = this.refs;
    if (today && !elementInViewport(today)) {
      today.scrollIntoView({ inline: "start" });
      document.querySelector(".weeks").scrollTop = 0;
    }
  };

  checkPincode = (e) => {
    e.preventDefault();
    const { pincode } = this.state;
    this.send("PINCODE", { pincode });
  };

  save = (action, ...cards) => {
    const { pincode, updating } = this.state;
    if (action === "UPDATE") {
      cards.forEach(
        (card) =>
          (card.modified = DateTime.now().toFormat("dd-MM-yyyy HH:mm:ss"))
      );
      this.setState({
        updating: updating.concat(cards.map((card) => card.id)),
      });
    } else if (action === "DELETE") {
      this.setState({ updating: updating.concat(cards) });
    }
    this.send("SAVE", { action, cards, pincode });
  };

  saveCardTypes = () => {
    const { board, pincode } = this.state;
    const { cardHolders, cardTypes } = board.settings;
    this.send("SAVE-CARD-TYPES", { cardTypes, cardHolders, pincode });
  };

  saveSettings = () => {
    const { board, pincode } = this.state;
    const { settings, adminPincode, userPincode, readerPincode } = board;
    this.send("SAVE-SETTINGS", {
      settings,
      adminPincode,
      userPincode,
      readerPincode,
      pincode,
    });
  };

  saveLocalSettings = () =>
    localStorage.setItem(
      this.props.directory,
      JSON.stringify(this.state.local)
    );

  getSlotId = (date, slot) => {
    const { type } = this.state.board.settings;
    if (type === "continuous") {
      return `${date.toFormat("dd-MM-yyyy")}-${slot}`;
    } else if (type === "fixed") {
      const day = date.weekday - 1;
      return `${day}-${slot}`;
    }
  };

  getCards = (slotId, ignore = []) =>
    this.state.board.cards.filter(
      (card) => card.slot === slotId && ignore.indexOf(card.id) === -1
    );

  getCard = (id) => this.state.board.cards.find((card) => card.id === id);

  getCardIndex = (id) =>
    this.state.board.cards.findIndex((card) => card.id === id);

  getCardType = (id) =>
    this.state.board.settings.cardTypes.find((card) => card.id === id);

  getActionsForType = (type) => {
    const { showFlags } = this.state.board.settings;
    const { hasChanged, card, expanded, showCardPicker, showFlagPicker } =
      this.state.active;
    const expand = {
      title: expanded ? "Verkleinen" : "Vergroten",
      onClick: this.expand,
      icon: cn("far", expanded ? "fa-compress-wide" : "fa-expand-wide"),
    };
    const palette = {
      title: "Kleur veranderen",
      className: cn({ active: showCardPicker }),
      onClick: this.toggleCardPicker,
      icon: "far fa-palette",
      roles: ["user", "admin"],
    };
    const flag = {
      title: "Markering",
      className: cn({ active: showFlagPicker }),
      onClick: () => {
        const { active } = this.state;
        if (active.card.flag) {
          this.handleOnChange(active.card, { flag: null });
          active.hasChanged = true;
          this.setState({ active });

          this.writeLog(
            `${this.logCardTitle(active.card)} markering verwijderd`
          );
        } else {
          this.toggleFlagPicker();
        }
      },
      icon: card.flag ? "fas fa-flag" : "far fa-flag",
      style: card.flag
        ? {
            color: card.flag,
            backgroundColor: "#fff",
            borderRadius: "50%",
            fontSize: ".75rem",
          }
        : null,
      roles: ["user", "admin"],
    };
    const time = {
      title: `Toegevoegd: ${card.added}${
        card.added !== card.modified ? `<br/>Gewijzigd: ${card.modified}` : ""
      }`,
      icon: "far fa-clock",
      roles: ["user", "admin"],
    };
    const close = {
      title: hasChanged ? "Opslaan en sluiten" : "Sluiten",
      onClick: this.deactivate,
      icon: "far fa-times",
    };
    const del = {
      title: "Verwijderen",
      icon: "far fa-trash-alt",
      onClick: this.delete,
      roles: ["user", "admin"],
    };
    const copy = {
      title: "Kopiëren",
      onClick: this.copy,
      icon: "far fa-copy",
      roles: ["user", "admin"],
    };

    switch (type) {
      case "slot-card":
        return [
          ...(showFlags ? [flag] : []),
          del,
          palette,
          copy,
          time,
          "spacer",
          ...(isApp ? [] : [expand]),
          {
            title: "Terug stoppen",
            onClick: this.putBack,
            icon: "far fa-level-down-alt",
            roles: ["user", "admin"],
          },
          close,
        ];
      case "card-holder":
        return [
          ...(showFlags ? [flag] : []),
          del,
          palette,
          copy,
          time,
          "spacer",
          close,
        ];
      default:
        return [];
    }
  };

  activate = (node, card, type) => {
    const { autoExpanded } = this.state.local;
    const position = this.getPosition(node);

    if (this.state.active) {
    }

    this.occupied(card.id);

    this.setState(
      {
        active: {
          card: _.cloneDeep(card),
          position,
          expanded: autoExpanded || type === "card-holder",
          type,
        },
        cardPicker: null,
        selected: {},
      },
      () => {
        const input = this.refs["active-card"].querySelector("[name]");
        if (input) {
          input.focus();
        }
        ReactTooltip.rebuild();
      }
    );
  };

  getNode = (id) => {
    const el = findDOMNode(this.refs[id]);
    const node = el.querySelector(".card");
    return node;
  };

  activateById = (id, type) => {
    const card = this.getCard(id);
    const node = this.getNode(id);
    this.activate(node, card, type);
  };

  goHome = () => {
    const now = DateTime.now();
    const el = document.querySelector(".weeks");
    el.scrollTop = 0;
    el.scrollLeft = 0;
    this.setState({ date: now, showMenu: false }, this.setSearchParams);
  };

  select = (node, card, selectInner) => {
    const position = this.getPosition(node);
    const { selected } = this.state;

    if (selected.hasOwnProperty(card.id)) {
      delete selected[card.id];
    } else {
      if (selectInner) {
        let innerCards = [];
        for (const s of Object.values(selected)) {
          innerCards = innerCards.concat(
            this.getCardsBetween(card.slot, s.card.slot)
          );
        }
        innerCards = innerCards.filter(
          (card) => !selected.hasOwnProperty(card.id)
        );
        innerCards.forEach((card) => {
          const node = this.getNode(card.id);
          if (node) this.select(node, card, false);
        });
      }
      selected[card.id] = { card: { ...card }, position };
    }
    this.setState({ selected });
  };

  doSwap = (id1, id2, cb) => {
    const { board } = this.state;
    const idx1 = this.getCardIndex(id1);
    const idx2 = this.getCardIndex(id2);
    const card1 = board.cards[idx1];
    const card2 = board.cards[idx2];
    board.cards.splice(idx1, 1, card2);
    board.cards.splice(idx2, 1, card1);
    this.setState({ board }, cb);
  };

  swap = (card1, card2) =>
    this.doSwap(card1.id, card2.id, () =>
      this.save("SWAP", card1.id, card2.id)
    );

  getCardsBetween = (slot1, slot2) => {
    const { settings, cards } = this.state.board;
    const { type } = settings;
    const possibleSlots = [];

    if (type === "continuous") {
      const [date1, s1] = this.unpack(slot1);
      const [date2, s2] = this.unpack(slot2);
      if (date1.hasSame(date2, "day")) {
        const min = Math.min(s1, s2);
        const diff = Math.abs(s1 - s2);

        for (let i = 1; i < diff; i++) {
          possibleSlots.push(`${date1.toFormat("dd-MM-yyyy")}-${i + min}`);
        }
      }
    } else if (type === "fixed") {
      const [c1, s1] = this.unpack(slot1);
      const [c2, s2] = this.unpack(slot2);
      if (c1 === c2) {
        const min = Math.min(s1, s2);
        const diff = Math.abs(s1 - s2);

        for (let i = 1; i < diff; i++) {
          possibleSlots.push(`${c1}-${i + min}`);
        }
      }
    }

    return cards.filter((card) => possibleSlots.includes(card.slot));
  };

  deactivate = () => {
    const { active, board } = this.state;
    if (!active) return;
    this.released();

    if (!active.hasChanged) {
      this.setState({ active: null });
      return;
    }

    if (active.type === "card-holder") {
      const card = this.getCardFromCardHolders(active.card.id);
      if (card) {
        _.merge(card, active.card);
      }
      this.setState({ active: null }, this.saveCardTypes);
    } else {
      const { cards } = board;
      const index = this.getCardIndex(active.card.id);
      cards.splice(index, 1, active.card);
      this.setState({ board, active: null }, () =>
        this.save("UPDATE", active.card)
      );
    }

    this.writeLog(`${this.logCardTitle(active.card)} gewijzigd`);
  };

  getPosition = (node) => {
    const { x, y } = node.getBoundingClientRect();
    return { top: y, left: x };
  };

  getCardFromCardHolders = (id, withHolder = false) => {
    const holders = this.state.board.settings.cardHolders;
    for (const holderIndex in holders) {
      const holder = holders[holderIndex];
      const stack = holder.stack || [];
      const index = stack.findIndex((card) => card.id === id);
      const card = stack[index];
      if (card) {
        if (withHolder) {
          return { card, index, holder, holderIndex };
        }
        return card;
      }
    }
  };

  getCardHolder = (id) =>
    this.state.board.settings.cardHolders.find((holder) => holder.id === id);

  getCardFromType = (id, clone = true) => {
    const cardType = this.getCardType(id);
    if (!cardType) {
      return;
    }
    cardType.stack = cardType.stack || [];
    if (!cardType.stack.length) {
      cardType.stack = [
        {
          title: cardType.defaultTitle ? cardType.defaultTitle.split(";") : [],
          body: cardType.defaultBody || "",
        },
      ];
    }
    const card = clone ? { ...cardType.stack[0] } : cardType.stack[0];
    card.color = cardType.color;
    card.id = cardType.id;
    return card;
  };

  toggleCardPicker = () => {
    const { active } = this.state;
    active.showCardPicker = !active.showCardPicker;
    active.showFlagPicker = false;
    active.hasChanged = true;
    this.setState({ active });
  };

  toggleFlagPicker = () => {
    const { active } = this.state;
    active.showFlagPicker = !active.showFlagPicker;
    active.showCardPicker = false;
    active.hasChanged = true;
    this.setState({ active });
  };

  copy = () => {
    const { active, board } = this.state;
    const { cards } = board;
    const card = this.create(active.card);

    if (active.type === "slot-card") {
      const nextSlot = this.nextSlot(active.card.slot);
      card.slot = nextSlot;
      cards.push(card);
      this.setState({ board }, () => this.save("UPDATE", card));
    } else if (active.type === "card-holder") {
      const cardHolder = this.getCardFromCardHolders(active.card.id, true);
      const idx = cardHolder.holder.stack.indexOf(cardHolder.card);
      cardHolder.holder.stack.splice(idx, 0, card);
      this.deactivate();
    }

    this.writeLog(
      `${this.logCardTitle(active.card)} gekopieerd ${this.logCardTitle(
        card,
        false
      )}`
    );
  };

  _move = (card, slot) => {
    const logTitle = this.logCardTitle(card);
    card.slot = slot;
    this.writeLog(
      `${logTitle} verplaatst naar ${this.logCardTitle(card, false)}`
    );
  };

  move = (card, columns, rows, ignore = []) => {
    const slot = this.calculateSlot(card.slot, columns, rows);
    const c = this.getCard(card.id);
    if (this.canDrop(slot, ignore)) {
      this._move(c, slot);
    }
  };

  moveSelected = (columns, rows) => {
    const { selected } = this.state;
    const ignore = Object.keys(selected);
    let changed = false;
    Object.values(selected).forEach(({ card }) => {
      const c = this.getCard(card.id);
      const slot = this.calculateSlot(c.slot, columns, rows);
      if (this.canDrop(slot, ignore) && slot !== c.slot) {
        this._move(c, slot);
        changed = true;
      }
    });
    return changed;
  };

  unpack = (slot) => {
    const { type } = this.state.board.settings;
    const parts = slot.split("-");
    if (type === "continuous") {
      const [day, month, year, slot] = parts;
      const date = DateTime.fromObject({ day, month, year });
      return [date, parseInt(slot)];
    } else if (type === "fixed") {
      const [column, slot] = parts;
      return [parseInt(column), parseInt(slot)];
    }
  };

  calculateSlot = (slot, columns, rows) => {
    const { type, days, slots } = this.state.board.settings;
    const [column, s] = this.unpack(slot);

    const row = Math.min(parseInt(slots) - 1, Math.max(s + rows, 0));

    if (type === "continuous") {
      let date = column.plus({ days: columns });
      while (!days.includes(date.weekday - 1)) {
        date = date.plus({ days: columns });
      }
      return `${date.toFormat("dd-MM-yyyy")}-${row}`;
    }
    return `${parseInt(column) + columns}-${row}`;
  };

  nextSlot = (slot) => {
    const nextSlot = this.calculateSlot(slot, 1, 0);
    const [next] = this.getCards(nextSlot);
    return next ? this.nextSlot(nextSlot) : nextSlot;
  };

  showSearch = () => {
    this.popup(
      {
        id: "search",
        type: "search",
        description: ["Zoeken"],
        filteredCards: [],
        cards: () => this.state.popup.filteredCards,
        full: true,
      },
      () => {
        if (this.refs.searchInput) {
          this.refs.searchInput.select();
          this.refs.searchInput.focus();
        }
      }
    );
  };

  search = (e) => {
    e.preventDefault();
    const { popup, board } = this.state;
    const { searchInput, fromDate, toDate } = this.refs;
    const parsedFromDate = DateTime.fromFormat(
      fromDate.value,
      "yyyy-MM-dd"
    ).startOf("day");
    const parsedToDate = DateTime.fromFormat(
      toDate.value,
      "yyyy-MM-dd"
    ).startOf("day");
    const search = searchInput.value.toLowerCase();
    if (search) {
      const f = (card) => {
        if (!card) return false;
        const matchesQuery = card.title
          .concat([card.body || ""])
          .some((title) => title && title.toLowerCase().indexOf(search) !== -1);
        if (!matchesQuery) return false;
        if (fromDate && toDate) {
          if (card.slot) {
            const [date] = this.unpack(card.slot);
            if (fromDate.value && parsedFromDate > date.startOf("day"))
              return false;
            if (toDate.value && parsedToDate < date.startOf("day"))
              return false;
          } else {
            return !fromDate.value && !toDate.value;
          }
        }
        return true;
      };
      const cardsFiltered = board.cards.filter(f).sort(this.sortByDate);
      const cardHolderFiltered = _.flatten(
        board.settings.cardHolders.map((holder) => holder.stack)
      ).filter(f);
      const cards = cardsFiltered.concat(cardHolderFiltered);
      this.popup({
        ...popup,
        search,
        filteredCards: cards.slice(0, 50),
        totalFound: cards.length,
      });
    } else {
      this.popup({ ...popup, search, filteredCards: [], totalFound: 0 });
    }
  };

  cardHolderPopup = (cardHolder, config) => {
    const { defaultPopupSize } = this.state.local;
    this.popup({
      id: cardHolder.id,
      description: [cardHolder.description || "Naamloos"],
      cards: () => {
        const holder = this.getCardHolder(cardHolder.id);
        return holder.stack || [];
      },
      type: "card-holder",
      onAdd: (type) => this.add(cardHolder.id, type, false),
      onSort: (sortReverse) => {
        this.doSort(cardHolder.stack);
        this.popup({ sortReverse: !sortReverse });
        this.saveCardTypes();
      },
      onDelete: () => {
        const { selected } = this.state;
        cardHolder.stack = cardHolder.stack.filter(
          (card) => !selected.hasOwnProperty(card.id)
        );
        this.forceUpdate(this.saveCardTypes);
      },
      full: defaultPopupSize === "full",
      ...config,
    });
  };

  getHolderDescription = (id) => {
    const holder = this.getCardHolder(id);
    if (holder) {
      return [holder.description];
    }
  };

  getSlotDescription = (slot) => {
    try {
      const [c, row] = this.unpack(slot);
      const { type, slotLabels, headers } = this.state.board.settings;
      const col =
        type === "fixed"
          ? headers[c]
          : c.toLocaleString({
              weekday: "short",
              month: "short",
              day: "numeric",
            });
      return [slotLabels[row], col];
    } catch (e) {
      return [];
    }
  };

  slotPopup = (id) => {
    this.popup({
      id,
      cards: () => this.getCards(id),
      description: this.getSlotDescription(id),
      type: "slot-card",
      onAdd: (type) => this.add(id, type),
    });
  };

  popup = (config, cb) => {
    const { popup } = this.state;
    if (popup && config) {
      config = { ...popup, ...config };
    }
    if (!popup) {
      config.key = nanoid();
    }
    this.setState({ popup: config, showMenu: false }, cb);
  };

  expand = () => {
    const { active } = this.state;
    active.expanded = !active.expanded;
    this.setState({ active });
  };

  putBack = () => {
    const { card } = this.state.active;
    const cardType = this.getCardType(card.cardTypeId);
    if (cardType) {
      cardType.stack = cardType.stack || [];
      cardType.stack.unshift({
        title: card.title,
        body: card.body,
      });
      this.saveCardTypes();
    }
    this.delete();
  };

  delete = () => {
    const { card, type } = this.state.active;

    if (type === "slot-card") {
      const { cards } = this.state.board;
      const idx = cards.indexOf(this.getCard(card.id));

      if (idx > -1) {
        cards.splice(idx, 1);
        this.released();
        this.setState({ active: null }, () => this.save("DELETE", card.id));
      }
    } else if (type === "card-holder") {
      const cardHolder = this.getCardFromCardHolders(card.id, true);
      const idx = cardHolder.holder.stack.indexOf(cardHolder.card);
      cardHolder.holder.stack.splice(idx, 1);
      this.deactivate();
    }

    this.writeLog(`${this.logCardTitle(card)} verwijderd`);
  };

  putSelectedBack = (item) => {
    const { selected, board } = this.state;
    const cards = Object.values(selected);

    const putBack = (card) => {
      const cardType = this.getCardType(card.cardTypeId);
      if (cardType) {
        cardType.stack = cardType.stack || [];
        cardType.stack.unshift({
          title: card.title,
          body: card.body,
        });
      }
    };

    if (!cards.length) {
      const { card, holder } = this.getCardFromCardHolders(item.id, true) || {};
      if (card && holder) {
        const index = holder.stack.indexOf(card);
        holder.stack.splice(index, 1);
        if (!holder.stack.length) {
          holder.stack = [];
        }
        putBack(card);
        this.forceUpdate(() => {
          this.saveCardTypes();
        });
      }
      return;
    }

    const updateCards = [];
    for (const { card } of cards) {
      putBack(card);
      const index = board.cards.indexOf(this.getCard(card.id));
      updateCards.push(card.id);
      board.cards.splice(index, 1);

      this.writeLog(`${this.logCardTitle(card)} terug`);
    }

    this.saveCardTypes();
    this.setState({ selected: {}, board }, () =>
      this.save("DELETE", ...updateCards)
    );
  };

  difference = (a, b) => {
    const [colA, slotA] = this.unpack(a);
    const [colB, slotB] = this.unpack(b);

    const { type } = this.state.board.settings;
    if (type === "continuous") {
      return [colA.diff(colB, "days").days, slotA - slotB];
    } else if (type === "fixed") {
      return [colA - colB, slotA - slotB];
    }
  };

  add = (slot, item, append = true) => {
    const card = this.getCard(item.id);
    const cardFromHolder = this.getCardFromCardHolders(item.id);
    const { selected } = this.state;
    const s = Object.values(selected);

    const dropSelected = (cb) => {
      const result = s
        .map(({ card }) => this.drop(slot, card, null, false, append))
        .filter(Boolean);
      this.setState({ selected: {} }, () => {
        this.saveCardTypes();
        if (cb) cb(result);
      });
      return result;
    };

    if (card) {
      const cardHolder = this.getCardHolder(slot);

      if (cardHolder) {
        return dropSelected(() =>
          this.save("DELETE", ...s.map(({ card }) => card.id))
        );
      } else if (card.slot === slot) {
        this.setState({ selected: {} });
        return;
        // const { cards } = this.state.board
        // cards.splice(cards.indexOf(card), 1)
        // if (append) {
        //   cards.push({ ...card })
        // } else {
        //   cards.unshift({ ...card })
        // }
        // this.setState({ selected: {} })
        // return
      }

      const [columns, rows] = this.difference(slot, card.slot);
      this.moveSelected(columns, rows);
      this.setState({ selected: {} }, () =>
        this.save("UPDATE", ...s.map(({ card }) => this.getCard(card.id)))
      );
      return card;
    } else if (cardFromHolder) {
      return dropSelected((result) => this.save("UPDATE", ...result));
    } else {
      return this.drop(slot, null, item, true, append);
    }
  };

  canDrop = (slot, ignore = []) => {
    const { multiple } = this.state.board.settings;
    return multiple || (!multiple && !this.getCards(slot, ignore).length);
  };

  drop = (slot, card, item, save = true, append = true) => {
    const { cards } = this.state.board;
    const cardHolder = this.getCardHolder(slot); // holder dragged to
    let cardTypeChanged = false;

    if (!this.canDrop(slot) && !cardHolder) {
      return;
    }

    if (card) {
      const { holder, index } =
        this.getCardFromCardHolders(card.id, true) || {}; // holder dragged from
      if (holder) {
        // Prevent error in this.logCardTitle
        delete card.slot;
      }
      const logTitle = this.logCardTitle(card);

      if (index >= 0) {
        holder.stack.splice(index, 1);
      }

      card.slot = slot;

      const idx = this.getCardIndex(card.id);
      if (idx >= 0) {
        cards.splice(idx, 1);
      }

      if (cardHolder) {
        cardHolder.stack = cardHolder.stack || [];
        delete card.slot;

        if (append) {
          cardHolder.stack.push({ ...card });
        } else {
          cardHolder.stack.unshift({ ...card });
        }

        this.writeLog(`${logTitle} terug ${cardHolder.description}`);
      } else {
        if (append) {
          cards.push({ ...card });
        } else {
          cards.unshift({ ...card });
        }

        if (save) {
          this.save("UPDATE", card);
        }

        this.writeLog(
          `${logTitle} gepland ${holder ? holder.description : ""}`
        );
      }

      if ((cardHolder || holder) && save) {
        this.saveCardTypes();
      }
    } else {
      const cardType = this.getCardType(item.id);
      card = this.getCardFromType(item.id);

      if (cardType.stack) {
        cardType.stack.shift();
        cardTypeChanged = true;
      }

      card.title =
        card.title ||
        (cardType.defaultTitle ? cardType.defaultTitle.split(";") : []) ||
        [];
      card.body = card.body || cardType.defaultBody || "";
      card.cardTypeId = cardType.id;
      card.slot = slot;
      card = this.create(card);
      const logTitle = this.logCardTitle(card);

      if (cardHolder) {
        delete card.slot;
        cardHolder.stack = cardHolder.stack || [];
        if (append) {
          cardHolder.stack.push(card);
        } else {
          cardHolder.stack.unshift(card);
        }

        this.writeLog(`${logTitle} terug ${cardHolder.description}`);
      } else {
        if (append) {
          cards.push(card);
        } else {
          cards.unshift(card);
        }

        if (save) {
          this.save("UPDATE", card);
        }

        this.writeLog(`${logTitle} gepland`);
      }

      if ((cardTypeChanged || cardHolder) && save) {
        this.saveCardTypes();
      }
    }

    this.forceUpdate();
    return card;
  };

  setSearchParams = () => {
    const { date } = this.state;
    const now = DateTime.now();
    const search = new URLSearchParams(window.location.search);
    if (date.year !== now.year) {
      search.set("year", date.year);
    } else {
      search.delete("year");
    }
    if (date.weekNumber !== now.weekNumber) {
      search.set("week", date.weekNumber);
    } else {
      search.delete("week");
    }
    window.history.replaceState(null, null, "?" + search.toString());
  };

  prevWeek = () => {
    this.toDate(this.state.date.minus({ week: 1 }));
  };

  nextWeek = () => {
    this.toDate(this.state.date.plus({ week: 1 }));
  };

  toDate = (date, cb) => {
    this.setState({ date }, () => {
      this.setSearchParams();
      if (cb) cb();
    });
  };

  prevDay = () => {
    const { date } = this.state;
    this.setState({ date: date.minus({ days: 1 }) });
  };

  nextDay = () => {
    const { date } = this.state;
    this.setState({ date: date.plus({ days: 1 }) });
  };

  logout = (confirm = true) => {
    if (!confirm || (confirm && window.confirm("Uitloggen?"))) {
      const { directory, logout } = this.props;
      sessionStorage.setItem(directory, "");
      logout();
    }
  };

  resetLocalSettings = () => {
    if (window.confirm("Weet je zeker dat je wilt resetten?")) {
      this.setState(
        { local: { ...LOCAL_SETTINGS }, showSettings: false },
        this.saveLocalSettings
      );
    }
  };

  resetSettings = () => {
    if (window.confirm("Weet je zeker dat je wilt resetten?")) {
      const { board } = this.state;
      board.settings = { ...ONLINE_SETTINGS };
      this.setState({ board, showAdminSettings: false }, this.saveSettings);
    }
  };

  create = (card) => ({
    ...card,
    id: nanoid(),
    added: DateTime.now().toFormat("dd-MM-yyyy HH:mm:ss"),
    modified: "",
  });

  handleResize = (e) => {
    const slot = document.querySelector(".slot");
    const slotWidth = slot ? slot.clientWidth - 26 : 150;
    this.setState({ appCardSize: slotWidth });
  };

  handleKeyPresses = (e) => {
    if (document.activeElement !== document.body) return;

    let { popup, selected, board } = this.state;
    const cards = Object.keys(selected).map((id) => this.getCard(id));

    switch (e.keyCode) {
      case 8:
      case 46:
        if (
          cards.length > 1 &&
          !window.confirm(
            `${cards.length} kaarten verwijderen?\n\nKlik op OK om door te gaan`
          )
        ) {
          return;
        }
        if (popup && popup.onDelete) {
          popup.onDelete();
        } else {
          board.cards = board.cards.filter(
            (card) => !selected.hasOwnProperty(card.id)
          );
          this.setState({ selected: {}, board }, () =>
            this.save("DELETE", ...cards.map((card) => card.id))
          );

          cards.forEach((card) => {
            this.writeLog(`${this.logCardTitle(card)} verwijderd`);
          });
        }
        break;
      case 27:
        this.setState({
          active: null,
          popup: null,
          showAdminSettings: false,
          showSettings: false,
        });
        break;
      case 37:
        if (selected) {
          e.preventDefault();
        }
        if (this.moveSelected(-1, 0)) {
          this.forceUpdate(() => this.save("UPDATE", ...cards));
        }
        break;
      case 38:
        if (selected) {
          e.preventDefault();
        }
        if (this.moveSelected(0, -1)) {
          this.forceUpdate(() => this.save("UPDATE", ...cards));
        }
        break;
      case 39:
        if (selected) {
          e.preventDefault();
        }
        if (this.moveSelected(1, 0)) {
          this.forceUpdate(() => this.save("UPDATE", ...cards));
        }
        break;
      case 40:
        if (selected) {
          e.preventDefault();
        }
        if (this.moveSelected(0, 1)) {
          this.forceUpdate(() => this.save("UPDATE", ...cards));
        }
        break;
      case 67:
        if ((e.ctrlKey || e.metaKey) && navigator.clipboard) {
          e.preventDefault();

          const cards = Object.values(selected).map(({ card }) => ({
            title: card.title,
            body: card.body,
            cardTypeId: card.cardTypeId,
            color: card.color,
          }));
          const data = new window.ClipboardItem({
            "text/plain": new Blob([JSON.stringify(cards)], {
              type: "text/plain",
            }),
          });
          navigator.clipboard
            .write([data])
            .then(() => this.setState({ selected: {} }))
            .catch((e) => console.error(e));
        }
        break;
      case 70:
        if (e.shiftKey) {
          this.toggleLatencyCheck();
        } else if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          this.showSearch();
        }
        break;
      case 86:
        if ((e.ctrlKey || e.metaKey) && navigator.clipboard) {
          e.preventDefault();
          navigator.clipboard
            .readText()
            .then((text) => JSON.parse(text))
            .then((data) => {
              // do something with this
              const { popup, cardPicker } = this.state;
              const slot = popup ? popup.id : cardPicker;
              if (slot) {
                const cards = data.map((card) => ({
                  ...this.create(card),
                  slot,
                }));
                board.cards = board.cards.concat(cards);
                this.save("UPDATE", ...cards);
                this.setState({ board, cardPicker: null }, () => {
                  cards.forEach((card) =>
                    this.select(
                      this.getNode(popup ? `popup-${card.id}` : card.id),
                      card,
                      false
                    )
                  );
                });
              }
            })
            .catch((e) => console.error(e));
        }
        break;
      default:
        break;
    }
  };

  handleEditCardTypes = (e) => {
    const { board } = this.state;
    const num = e.target.value;

    if (num > board.settings.cardTypes.length) {
      board.settings.cardTypes = (board.settings.cardTypes || []).concat(
        new Array(num - board.settings.cardTypes.length).fill().map((_, i) => ({
          id: nanoid(),
          description: `Kaartenbak ${(
            i +
            board.settings.cardTypes.length +
            1
          ).toString()}`,
          stack: [],
          color: randomColor(),
        }))
      );
    } else {
      board.settings.cardTypes.splice(num);
    }
    this.setState({ board });
  };

  addCardHolders = (num) => {
    const { board } = this.state;
    board.settings.cardHolders = (board.settings.cardHolders || []).concat(
      new Array(num).fill().map((_, i) => ({
        id: nanoid(),
        description: `Flexbak ${(
          i +
          board.settings.cardHolders.length +
          1
        ).toString()}`,
        stack: [],
      }))
    );
    this.setState({ board });
  };

  handleEditCardHolders = (e) => {
    const { board } = this.state;
    const num = e.target.value;

    if (num > board.settings.cardHolders.length) {
      this.addCardHolders(num - board.settings.cardHolders.length);
    } else {
      board.settings.cardHolders.splice(num);
      this.setState({ board });
    }
  };

  handleEditFlags = (e) => {
    const { board } = this.state;
    const num = e.target.value;

    if (num > board.settings.flags.length) {
      board.settings.flags = board.settings.flags.concat(
        new Array(num - board.settings.flags.length).fill().map(randomColor)
      );
    } else {
      board.settings.flags.splice(num);
    }

    this.setState({ board });
  };

  handleOnChange = (card, data) => {
    const hasChanged = Object.entries(data)
      .map(([name, value]) => !_.isEqual(value, card[name]))
      .some((changed) => changed);

    if (!hasChanged) {
      return false;
    }

    _.merge(card, data);
    this.forceUpdate();
    return true;
  };

  handleSettingsChange = (e) => {
    const { board, local } = this.state;
    const { settings } = board;
    let { name, value, type, checked, multiple, selectedOptions } = e.target;
    let settingsType;
    [settingsType, name] = name.split(".");
    const setting = settingsType === "local" ? local : settings;

    if (multiple) {
      value = Array.from(selectedOptions, (option) => option.value);
    }

    if (
      (name === "adminPincode" ||
        name === "userPincode" ||
        name === "readerPincode") &&
      !value
    ) {
      window.alert("Een pincode is verplicht");
      return;
    }

    if (name === "days") {
      const idx = settings.days.indexOf(parseInt(value));
      if (idx !== -1) {
        settings.days.splice(idx, 1);
      } else {
        settings.days.push(parseInt(value));
      }
    } else {
      if (type === "number") {
        setting[name] = value ? parseInt(value) : null;
      } else if (type === "checkbox") {
        setting[name] = value === "on" || !value ? checked : value;
      } else {
        setting[name] = value;
      }
    }

    if (name === "type" && value === "fixed") {
      settings.columns = 12;
      settings.slots = 31;
      settings.showSlotLabels = true;
      settings.showHeaders = true;
      settings.slotLabels = new Array(31).fill().map((_, day) => day + 1);
      settings.headers = [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
      ];
    }

    this.setState({ board, local });
  };

  _export = () => {
    const { adminPincode, userPincode, readerPincode, settings, cards } =
      this.state.board;
    const data = JSON.stringify(
      {
        adminPincode,
        userPincode,
        readerPincode,
        settings,
        cards,
      },
      null,
      2
    );
    return new Blob([data], { type: "application/json" });
  };

  download = (fileName, blob) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = fileName;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  requestDownload = (fileName) => {
    const { directory } = this.props;
    const { pincode } = this.state;
    this.send("DOWNLOAD-BACKUP", { pincode, directory, fileName });
  };

  clean = () => {
    const { pincode } = this.state;
    const to = document.querySelector("#to").value;
    const from = document.querySelector("#from").value;
    // reset
    document.querySelector("#to").value = "";
    document.querySelector("#from").value = "";

    if (window.confirm("Klik op OK om de database op te schonen")) {
      this.send("CLEAN", { to, from, pincode });
    }
  };

  backup = () => {
    const { directory } = this.props;
    const { pincode } = this.state;
    this.send("CREATE-BACKUP", { pincode, directory });
  };

  removeBackup = (fileName) => {
    const { directory } = this.props;
    const { pincode } = this.state;
    if (window.confirm("Klik op OK om door te gaan.")) {
      this.send("REMOVE-BACKUP", { pincode, directory, fileName });
    }
  };

  validate = (board) => {
    return (
      board.hasOwnProperty("cards") &&
      Array.isArray(board.cards) &&
      board.hasOwnProperty("settings") &&
      board.hasOwnProperty("userPincode") &&
      board.hasOwnProperty("adminPincode") &&
      board.hasOwnProperty("readerPincode")
    );
  };

  import = () => {
    const input = document.getElementById("import");
    const [file] = input.files;

    if (!file) {
      return window.alert("Selecteer een bestand");
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const board = JSON.parse(e.target.result);
        if (this.validate(board)) {
          board.role = this.state.board.role;
          board.backups = this.state.board.backups;

          if (window.confirm("Data kan worden geimporteerd")) {
            this.send("IMPORT", { board, pincode: this.state.pincode });
            this.setState({ board, showAdminSettings: false });
          }
        }
      } catch (e) {
        return window.alert("Data kan niet worden geimporteerd");
      }
    };
    reader.readAsText(file);
  };

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  doSort = (cards) => {
    const { popup, board } = this.state;
    const { cardHolderSort } = board.settings;

    if (!cardHolderSort) return;
    cards.sort(this.sortBySetting);

    if (popup.sortReverse) {
      cards.reverse();
    }
    return cards;
  };

  sortByDate = (a, b) => {
    const [colA] = this.unpack(a.slot);
    const [colB] = this.unpack(b.slot);
    if (colA > colB) {
      return 1;
    } else if (colB > colA) {
      return -1;
    }
    return 0;
  };

  sortBySetting = (a, b) => {
    const { cardHolderSort } = this.state.board.settings;
    let sortA = _.get(a, cardHolderSort);
    let sortB = _.get(b, cardHolderSort);
    if (sortA) {
      sortA = sortA.toLowerCase().trim();
    }
    if (sortB) {
      sortB = sortB.toLowerCase().trim();
    }
    if (sortA > sortB || !sortB) {
      return 1;
    }
    if (sortB > sortA || !sortA) {
      return -1;
    }
    return 0;
  };

  render = () => {
    const { directory, changeDirectory } = this.props;
    const {
      occupied,
      updating,
      latency,
      hasJSError,
      JSError,
      JSErrorInfo,
      showMenu,
      appCardSize,
      connections,
      showReleaseNotes,
      popup,
      cardPicker,
      pincode,
      selected,
      loading,
      isDragging,
      error,
      active,
      date,
      showSettings,
      showAdminSettings,
      loggedIn,
      local,
      board,
      log,
    } = this.state;
    const { role, settings, backups = [] } = board;
    const { cardWidth, cardHeight } = local;
    const {
      cardHolderSort,
      backup,
      backupEmail,
      alignment,
      type,
      columns,
      multiple,
      slots,
      headers = [],
      flags = ONLINE_SETTINGS.flags,
      showFlags,
      resizeEnabled,
      showHeaders,
      slotLabels = [],
      showSlotLabels,
      cardTypes,
      cardHolders,
      titleLines,
    } = settings;
    const weeks = isApp
      ? 1
      : type === "continuous"
      ? local.weeks || settings.weeks
      : 1;
    const days = isApp
      ? []
      : type === "continuous"
      ? settings.days
      : new Array(columns).fill().map((_, day) => day);

    if (isApp) {
      let addDays = 1;
      if (window.innerWidth >= 420) {
        addDays += 1;
      }
      if (window.innerWidth >= 1024) {
        addDays += 1;
      }
      if (window.innerWidth >= 1366) {
        addDays += 1;
      }
      let tmp = DateTime.fromObject(date.toObject()).minus({ days: 1 });
      new Array(addDays).fill().forEach((_, day) => {
        tmp = tmp.plus({ days: 1 });
        while (!settings.days.includes(tmp.weekday - 1)) {
          tmp = tmp.plus({ days: 1 });
        }
        days.push(tmp);
      });
    }

    if (hasJSError) {
      return (
        <div className="board loading" style={{ fontSize: "1rem" }}>
          <i
            className="far fa-exclamation-triangle"
            style={{ color: "#c3423f", marginBottom: "1rem", fontSize: "4rem" }}
          />
          <div style={{ textAlign: "center" }}>
            <strong>Er is iets fout gegaan</strong>
            <p>
              De applicatie kon niet juist <br />
              worden weergegeven
            </p>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {JSError && JSError.toString()}
              <br />
              {JSErrorInfo ? JSErrorInfo.componentStack : null}
            </details>
          </div>
          <button
            className="button-round button-primary"
            onClick={() => window.location.reload()}
          >
            Probeer opnieuw
          </button>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="board loading">
          <div>
            <i className="fad fa-spin fa-spinner-third" />
          </div>
        </div>
      );
    }

    /*
    <div className={`offline${!online ? ' show' : ' online'}`}>
      {!online ? (
        <>
          Er is geen verbinding met t-card.nl. Een moment geduld...<i className="fad fa-spin fa-spinner-third" style={{ marginLeft: '.25rem' }} />
        </>
      ) : 'Verbonden met t-card.nl.'}
    </div>
    */

    return (
      <div
        className={cn("board", type, {
          "show-menu": showMenu && isApp,
          "has-active-card": !!active && active.type === "slot-card" && !popup,
          app: isApp,
        })}
        style={{
          "--card-alignment": alignment,
          "--card-width": `${
            isApp ? appCardSize : cardWidth || settings.cardWidth || 150
          }px`,
          "--card-height": `${
            isApp ? appCardSize : cardHeight || settings.cardHeight || 150
          }px`,
          "--card-title-lines": titleLines,
          "--app-num-days": days.length,
        }}
      >
        {!isApp ? (
          <div
            className="app-version"
            onClick={() => this.setState({ showReleaseNotes: true })}
          >
            Versie {version}
          </div>
        ) : null}
        {latency !== undefined ? (
          <span className={cn("latency", { slow: latency >= 50 })}>
            {latency}ms
          </span>
        ) : null}
        {showReleaseNotes ? (
          <>
            <div className="active-card expanded">
              <Card
                color="#333333"
                title={["Release notes"]}
                body={releaseNotes()}
              >
                <div className="card-actions">
                  <button
                    onClick={() => this.setState({ showReleaseNotes: false })}
                    style={{ marginLeft: "auto" }}
                    data-tip="Sluiten"
                  >
                    <i className="far fa-times" />
                  </button>
                </div>
              </Card>
            </div>
            <div
              className="backdrop"
              onClick={() => this.setState({ showReleaseNotes: false })}
            />
          </>
        ) : null}
        {!loggedIn ? (
          <form
            autoComplete="off"
            className="pincode"
            onSubmit={this.checkPincode}
          >
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <div className="logo">
              <img src={logo} alt="Logo T-card planning" />
            </div>
            <div className="login">
              {changeDirectory ? (
                <input
                  type="text"
                  defaultValue={directory}
                  onBlur={(e) => changeDirectory(e.target.value)}
                  placeholder="Naam"
                />
              ) : (
                <input type="text" value={directory.toUpperCase()} disabled />
              )}
              <input
                autoComplete="off"
                type="password"
                data-lpignore="true"
                value={pincode}
                placeholder="Pincode"
                onChange={(e) =>
                  this.setState({ error: "", pincode: e.target.value })
                }
                onKeyUp={(e) =>
                  e.keyCode === 13 ? this.checkPincode(e) : null
                }
              />
              <button className="submit" type="submit">
                <i className="far fa-arrow-right" />
              </button>
            </div>
            <div className="title">
              T-Card planning{" "}
              <a
                href="https://stersoftware.nl"
                rel="noreferrer"
                target="_blank"
                className="text-primary"
              >
                Ster Software B.V.
              </a>
            </div>
            {error ? <span className="error">{error}</span> : null}
          </form>
        ) : (
          <DndProvider options={Touch}>
            <Preview key="preview">
              {({ itemType, item, style }) => {
                const cardType = this.getCardFromType(item.id);
                const cardHolder = this.getCardFromCardHolders(item.id);
                const card = this.getCard(item.id, true);
                const _card = cardType || cardHolder || card;
                if (item.top) {
                  const { m41, m42 } = new window.WebKitCSSMatrix(
                    style.transform
                  );
                  style.transform =
                    style.WebkitTransform = `translate(${m41}px, ${
                      m42 + item.top
                    }px)`;
                }
                return (
                  <>
                    {!Object.keys(selected).length ? (
                      _card ? (
                        <Card
                          numTitleLines={titleLines}
                          preview
                          selected
                          {..._card}
                          style={style}
                        />
                      ) : null
                    ) : (
                      Object.values(selected).map(({ card, position }) => {
                        const newStyle = { ...style };
                        if (item.id !== card.id) {
                          const offsetX =
                            selected[item.id].position.left - position.left;
                          const offsetY =
                            selected[item.id].position.top - position.top;
                          const { m41, m42 } = new window.WebKitCSSMatrix(
                            newStyle.transform
                          );
                          newStyle.transform =
                            newStyle.WebkitTransform = `translate(${
                              m41 - offsetX
                            }px, ${m42 - offsetY}px)`;
                        }
                        return (
                          <Card
                            numTitleLines={titleLines}
                            preview
                            selected
                            key={card.id}
                            {...card}
                            style={newStyle}
                          />
                        );
                      })
                    )}
                  </>
                );
              }}
            </Preview>
            <ReactTooltip className="tooltip" effect="solid" multiline />
            <Weeks key={type}>
              {isApp ? (
                <>
                  <div className="menu" key="menu">
                    <ul>
                      <li>
                        <button onClick={this.goHome}>
                          <i className="far fa-house" /> Naar vandaag
                        </button>
                      </li>
                      {role === "admin" ? (
                        <li>
                          <button
                            onClick={() =>
                              this.setState({
                                showAdminSettings: "general",
                                showMenu: false,
                              })
                            }
                          >
                            <i className="far fa-cog" /> Instellingen
                          </button>
                        </li>
                      ) : null}
                      {role !== "reader" ? (
                        <li>
                          <button
                            onClick={() =>
                              this.setState({
                                showSettings: "general",
                                showMenu: false,
                              })
                            }
                          >
                            <i className="far fa-user-cog" /> Voorkeuren
                          </button>
                        </li>
                      ) : null}
                      <li>
                        <button onClick={this.logout} className="text-danger">
                          <i className="far fa-sign-out" /> Uitloggen
                        </button>
                      </li>
                      {role !== "reader" ? (
                        <>
                          <li
                            className="separator"
                            style={{ marginBottom: "auto" }}
                          />
                          {cardHolders.map((cardHolder, idx) => (
                            <li className="menu-card-holder" key={idx}>
                              <button
                                onClick={() => this.cardHolderPopup(cardHolder)}
                              >
                                <span
                                  className="color"
                                  style={{
                                    backgroundColor:
                                      cardHolder.stack &&
                                      cardHolder.stack.length
                                        ? cardHolder.stack[0].color
                                        : null,
                                  }}
                                />
                                <div className="card-description">
                                  {cardHolder.description}
                                </div>
                                {cardHolder.stack && cardHolder.stack.length ? (
                                  <span className="card-count">
                                    {cardHolder.stack.length}
                                  </span>
                                ) : null}
                              </button>
                            </li>
                          ))}
                        </>
                      ) : null}
                    </ul>
                    <div
                      className="app-version"
                      onClick={() => this.setState({ showReleaseNotes: true })}
                    >
                      Versie {version}
                    </div>
                  </div>
                </>
              ) : null}
              {isApp ? (
                <button className="show-menu" onClick={this.toggleMenu}>
                  <i className="far fa-bars" />
                </button>
              ) : showSlotLabels ? (
                <img
                  src={logoTransparent}
                  alt="Logo T-card planning"
                  className="go-home"
                  onClick={this.goHome}
                />
              ) : null}
              {weeks > 0
                ? new Array(weeks).fill().map((_, week) => {
                    const weekDate = date.plus({ week });
                    const { weekNumber, year } = weekDate;
                    return (
                      <React.Fragment key={weekNumber}>
                        {showSlotLabels ? (
                          <div
                            className={cn("slot-labels", {
                              "with-headers":
                                showHeaders || type === "continuous",
                            })}
                          >
                            {slots > 0
                              ? new Array(slots).fill().map((_, slot) => (
                                  <div className="slot-label" key={slot}>
                                    {slotLabels[slot]}
                                  </div>
                                ))
                              : null}
                          </div>
                        ) : null}
                        <div className="week">
                          {type === "continuous" && !isApp ? (
                            <div className="week-number">
                              <button
                                type="button"
                                onClick={() => this.prevWeek()}
                              >
                                <i className="fas fa-chevron-left" />
                              </button>
                              <span style={{ lineHeight: 1 }}>
                                Week {weekNumber}
                                <br />
                                {year}
                              </span>
                              <button
                                type="button"
                                onClick={() => this.nextWeek()}
                              >
                                <i className="fas fa-chevron-right" />
                              </button>
                            </div>
                          ) : null}
                          <div className="days">
                            {days.length > 0
                              ? days.map((day, idx) => {
                                  const date =
                                    day instanceof DateTime
                                      ? day
                                      : weekDate.plus({ day });
                                  const today = date.hasSame(
                                    DateTime.now(),
                                    "day"
                                  );
                                  return (
                                    <div
                                      className={cn("day", {
                                        weekend:
                                          [0, 6].includes(date.weekday) &&
                                          type === "continuous",
                                      })}
                                      key={day}
                                      ref={today ? "today" : null}
                                    >
                                      {type === "continuous" ? (
                                        <div
                                          className={cn("day-title", { today })}
                                        >
                                          {idx === 0 && isApp ? (
                                            <button
                                              className="prev-day"
                                              onClick={this.prevDay}
                                            >
                                              <i className="fas fa-chevron-left" />
                                            </button>
                                          ) : null}
                                          {date.toLocaleString({
                                            weekday: "short",
                                            month: "short",
                                            day: "numeric",
                                          })}
                                          {days.length - 1 === idx && isApp ? (
                                            <button
                                              className="next-day"
                                              onClick={this.nextDay}
                                            >
                                              <i className="fas fa-chevron-right" />
                                            </button>
                                          ) : null}
                                        </div>
                                      ) : (
                                        showHeaders && (
                                          <div className="day-title">
                                            {idx === 0 && isApp ? (
                                              <button
                                                className="prev-day"
                                                onClick={this.prevDay}
                                              >
                                                <i className="fas fa-chevron-left" />
                                              </button>
                                            ) : null}
                                            {headers[idx] || idx + 1}
                                            {days.length - 1 === idx &&
                                            isApp ? (
                                              <button
                                                className="next-day"
                                                onClick={this.nextDay}
                                              >
                                                <i className="fas fa-chevron-right" />
                                              </button>
                                            ) : null}
                                          </div>
                                        )
                                      )}
                                      {slots > 0
                                        ? new Array(slots)
                                            .fill()
                                            .map((_, slot) => {
                                              const id = this.getSlotId(
                                                date,
                                                slot
                                              );
                                              const cards = this.getCards(id);
                                              const hasCardPicker =
                                                role !== "reader" &&
                                                cards.length === 0 &&
                                                (isApp ||
                                                  (local.hasOwnProperty(
                                                    "cardPicker"
                                                  )
                                                    ? local.cardPicker
                                                    : settings.cardPicker));
                                              return (
                                                <Slot
                                                  key={id}
                                                  onDropped={(item) => {
                                                    const cards = this.add(
                                                      id,
                                                      item
                                                    );
                                                    const card = Array.isArray(
                                                      cards
                                                    )
                                                      ? cards[0]
                                                      : cards;
                                                    if (
                                                      local.autoOpen &&
                                                      cards &&
                                                      cards.length === 1 &&
                                                      !card.title
                                                        .join("")
                                                        .replace(
                                                          /(?:\r\n|\r|\n)/g,
                                                          ""
                                                        ) &&
                                                      !card.body.replace(
                                                        /(?:\r\n|\r|\n)/g,
                                                        ""
                                                      )
                                                    ) {
                                                      setTimeout(() =>
                                                        this.activate(
                                                          this.getNode(card.id),
                                                          card,
                                                          "slot-card"
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  className={cn({
                                                    "has-card-picker":
                                                      hasCardPicker,
                                                  })}
                                                  onClick={(e) => {
                                                    if (
                                                      isDragging ||
                                                      !(
                                                        e.target.classList.contains(
                                                          "slot"
                                                        ) ||
                                                        e.target.classList.contains(
                                                          "slot-border"
                                                        )
                                                      )
                                                    ) {
                                                      return;
                                                    }
                                                    if (hasCardPicker) {
                                                      if (cardPicker !== id) {
                                                        this.setState({
                                                          cardPicker: id,
                                                        });
                                                      }
                                                      // } else if (cards.length > 0) {
                                                    } else {
                                                      this.slotPopup(id);
                                                    }
                                                  }}
                                                  multiple={cards.length > 1}
                                                >
                                                  {cards.map((card, idx) => (
                                                    <DraggableCard
                                                      key={idx}
                                                      numTitleLines={titleLines}
                                                      onClick={(e) =>
                                                        (e.ctrlKey ||
                                                          e.metaKey ||
                                                          e.shiftKey) &&
                                                        !active
                                                          ? this.select(
                                                              e.target.closest(
                                                                ".card"
                                                              ),
                                                              card,
                                                              e.shiftKey
                                                            )
                                                          : this.activate(
                                                              e.target.closest(
                                                                ".card"
                                                              ),
                                                              card,
                                                              "slot-card"
                                                            )
                                                      }
                                                      onBeginDrag={(node) => {
                                                        if (
                                                          !selected.hasOwnProperty(
                                                            card.id
                                                          )
                                                        ) {
                                                          this.select(
                                                            node,
                                                            card
                                                          );
                                                        }
                                                        this.occupied(
                                                          ...Object.keys(
                                                            selected
                                                          )
                                                        );
                                                        this.setState({
                                                          isDragging:
                                                            Object.keys(
                                                              selected
                                                            ),
                                                        });
                                                      }}
                                                      onEndDrag={() => {
                                                        this.released();
                                                        setTimeout(() =>
                                                          this.setState({
                                                            isDragging: false,
                                                          })
                                                        );
                                                      }}
                                                      updating={
                                                        updating.indexOf(
                                                          card.id
                                                        ) !== -1
                                                      }
                                                      selected={selected.hasOwnProperty(
                                                        card.id
                                                      )}
                                                      cantDrag={
                                                        !!active ||
                                                        role === "reader"
                                                      }
                                                      disabled={occupied.includes(
                                                        card.id
                                                      )}
                                                      ref={card.id}
                                                      showFlag={showFlags}
                                                      resizeEnabled={
                                                        resizeEnabled
                                                      }
                                                      {...card}
                                                    />
                                                  ))}
                                                  <span className="slot-border" />
                                                  {cardPicker === id ? (
                                                    <CardPicker
                                                      onOutsideClick={() =>
                                                        this.setState({
                                                          cardPicker: null,
                                                        })
                                                      }
                                                    >
                                                      {cardTypes.map(
                                                        (type, idx) => (
                                                          <div
                                                            key={idx}
                                                            className="card-type"
                                                            style={{
                                                              backgroundColor:
                                                                type.color,
                                                            }}
                                                            onClick={() => {
                                                              const card =
                                                                this.add(
                                                                  id,
                                                                  type
                                                                );
                                                              this.setState(
                                                                {
                                                                  cardPicker:
                                                                    null,
                                                                },
                                                                () =>
                                                                  this.activateById(
                                                                    card.id,
                                                                    "slot-card"
                                                                  )
                                                              );
                                                            }}
                                                          />
                                                        )
                                                      )}
                                                    </CardPicker>
                                                  ) : null}
                                                </Slot>
                                              );
                                            })
                                        : null}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })
                : null}
            </Weeks>
            {role !== "reader" && !isApp ? (
              <div
                className="card-types"
                style={{
                  "--card-types": cardTypes.length + cardHolders.length,
                }}
              >
                <CardTypes
                  onDropped={(item) => this.putSelectedBack(item)}
                  className={cn({ visible: isDragging })}
                  style={{ "--card-types": cardTypes.length }}
                />
                {cardTypes.map((type, idx) => {
                  const card = _.cloneDeep(this.getCardFromType(type.id));

                  if (type.id === isDragging) {
                    card.title = type.defaultTitle
                      ? type.defaultTitle.split(";")
                      : [];
                    card.body = type.defaultBody || "";
                  }
                  return (
                    <DraggableCard
                      className="card-type"
                      style={{ "--card-type-index": idx }}
                      key={card.id}
                      editable
                      numTitleLines={titleLines}
                      onBeginDrag={() =>
                        this.setState({ selected: {}, isDragging: type.id })
                      }
                      onEndDrag={() =>
                        setTimeout(() => this.setState({ isDragging: false }))
                      }
                      showFlag={showFlags}
                      disabled={occupied.includes(card.id)}
                      onSave={(data) => {
                        if (
                          this.handleOnChange(
                            this.getCardFromType(type.id, false),
                            data
                          )
                        ) {
                          this.saveCardTypes();
                        }
                      }}
                      {...card}
                    >
                      <div className="card-description">
                        <TextFill>{type.description}</TextFill>
                      </div>
                    </DraggableCard>
                  );
                })}
                {cardHolders.map((cardHolder, idx) => {
                  let stack = [...(cardHolder.stack || [])];

                  if (
                    Array.isArray(isDragging) &&
                    cardHolder.id === isDragging[0]
                  ) {
                    stack = stack.filter(
                      (card) => !selected.hasOwnProperty(card.id)
                    );
                  }

                  return (
                    <CardHolder
                      key={cardHolder.id}
                      onDropped={(item) => this.add(cardHolder.id, item, false)}
                      style={{ "--card-type-index": idx + cardTypes.length }}
                    >
                      {stack.length ? (
                        stack.slice(0, 1).map((card, idx) => (
                          <DraggableCard
                            key={card.id}
                            numTitleLines={titleLines}
                            onBeginDrag={(node) => {
                              if (!selected.hasOwnProperty(card.id)) {
                                this.select(node, card);
                              }
                              this.setState({
                                isDragging: [cardHolder.id, idx],
                                cardHolderExpanded: null,
                              });
                            }}
                            onEndDrag={() =>
                              setTimeout(() =>
                                this.setState({ isDragging: false })
                              )
                            }
                            cardProps={{ style: { "--card-index": idx } }}
                            selected={selected.hasOwnProperty(card.id)}
                            disabled={occupied.includes(card.id)}
                            onSave={(data) => {
                              if (this.handleOnChange(card, data)) {
                                this.saveCardTypes();
                              }
                            }}
                            showFlag={showFlags}
                            ref={card.id}
                            {...card}
                          />
                        ))
                      ) : (
                        <Card
                          key="empty"
                          title={[]}
                          body=""
                          color="#e6e6e6"
                          editable
                          inputProps={{ readOnly: true }}
                        />
                      )}
                      {stack.length && stack.length >= 1 ? (
                        <div
                          className="card-count"
                          onClick={() => this.cardHolderPopup(cardHolder)}
                        >
                          {stack.length}
                        </div>
                      ) : null}
                      {cardHolder.description ? (
                        <div
                          className="card-description"
                          onClick={() => this.cardHolderPopup(cardHolder)}
                        >
                          <TextFill>{cardHolder.description}</TextFill>
                        </div>
                      ) : null}
                    </CardHolder>
                  );
                })}
              </div>
            ) : null}

            {popup ? (
              <React.Fragment key={popup.key}>
                {((cards) => (
                  <div className={cn("popup", { full: popup.full || isApp })}>
                    <div className="header">
                      {!!popup.onSort ? (
                        <button
                          className="popup-sort"
                          onClick={() => popup.onSort(!!popup.sortReverse)}
                        >
                          <i
                            className={
                              popup.sortReverse
                                ? "far fa-sort-alpha-up"
                                : "far fa-sort-alpha-down"
                            }
                          />
                        </button>
                      ) : null}
                      {showFlags ? (
                        <div className="flags">
                          {[
                            ...new Set(
                              cards.map((card) => card.flag).filter(Boolean)
                            ),
                          ].map((flag) => (
                            <div
                              key={flag}
                              className={cn("flag", {
                                active: flag === popup.flag,
                              })}
                              style={{ color: flag }}
                              onClick={() => {
                                this.popup({
                                  flag: popup.flag === flag ? null : flag,
                                });
                              }}
                            />
                          ))}
                        </div>
                      ) : null}
                      <div className="description">
                        {popup.description.map((description, idx) => (
                          <span key={idx}>{description}</span>
                        ))}
                        <div
                          className="card-count"
                          onClick={() => {
                            if (Object.keys(selected).length) {
                              cards = cards.filter((card) =>
                                selected.hasOwnProperty(card.id)
                              );
                            }
                            cards.forEach((card) =>
                              this.select(
                                this.getNode(`popup-${card.id}`),
                                card,
                                false
                              )
                            );
                          }}
                        >
                          {cards.length}
                        </div>
                      </div>
                      {!isApp ? (
                        <button
                          className="popup-expand"
                          onClick={() => this.popup({ full: !popup.full })}
                        >
                          <i
                            className={
                              popup.full
                                ? "far fa-compress-wide"
                                : "far fa-expand-wide"
                            }
                          />
                        </button>
                      ) : null}
                      <button
                        className="popup-close"
                        onClick={() =>
                          this.setState({ popup: null, selected: {} })
                        }
                      >
                        <i className="far fa-times" />
                      </button>
                    </div>
                    <div className="body">
                      {popup.type === "search" ? (
                        <form className="field search" onSubmit={this.search}>
                          <input placeholder="Zoeken..." ref="searchInput" />
                          {type === "continuous" && (
                            <>
                              <input
                                type="date"
                                style={{ marginLeft: ".5rem", width: 250 }}
                                ref="fromDate"
                                placeholder="Van"
                              />
                              <input
                                type="date"
                                style={{ marginLeft: ".5rem", width: 250 }}
                                ref="toDate"
                                placeholder="Tot"
                              />
                            </>
                          )}
                          <button type="submit" style={{ marginLeft: ".5rem" }}>
                            <i className="far fa-search" />
                          </button>
                        </form>
                      ) : null}
                      {popup.search && !cards.length ? (
                        <div style={{ marginBottom: "1rem" }}>
                          Geen resultaten
                        </div>
                      ) : null}
                      <div className="cards">
                        {local.cardPicker && popup.type !== "search" ? (
                          <div>
                            <div className="new-card">
                              {cardTypes.map((type, idx) => (
                                <div
                                  key={idx}
                                  className="card-type"
                                  style={{ backgroundColor: type.color }}
                                  onClick={() => popup.onAdd(type)}
                                />
                              ))}
                            </div>
                          </div>
                        ) : null}
                        {cards.map((card, idx) => (
                          <DraggableCard
                            key={idx}
                            numTitleLines={titleLines}
                            onBeginDrag={(node) => {
                              if (!selected.hasOwnProperty(card.id)) {
                                this.select(node, card);
                              }
                              this.occupied(...Object.keys(selected));
                              this.setState({
                                isDragging: Object.keys(selected),
                                popup: null,
                              });
                            }}
                            onEndDrag={() => {
                              this.released();
                              setTimeout(() =>
                                this.setState({ isDragging: false })
                              );
                            }}
                            selected={
                              selected.hasOwnProperty(card.id) &&
                              popup.type !== "search"
                            }
                            disabled={occupied.includes(card.id)}
                            cantDrag={popup.type === "search"}
                            onClick={(e) => {
                              if (popup.type === "search") {
                                const holder = this.getCardHolder(card.slot);
                                if (holder) {
                                  this.select(
                                    e.target.closest(".card"),
                                    card,
                                    false
                                  );
                                  this.cardHolderPopup(holder);
                                } else {
                                  this.setState({ popup: null });
                                  if (type === "fixed") {
                                    this.select(
                                      this.getNode(card.id),
                                      card,
                                      false
                                    );
                                  } else {
                                    const [date] = this.unpack(card.slot);
                                    this.toDate(date, () => {
                                      this.select(
                                        this.getNode(card.id),
                                        card,
                                        false
                                      );
                                    });
                                  }
                                }
                              } else if (e.ctrlKey || e.metaKey || e.shiftKey) {
                                this.select(
                                  e.target.closest(".card"),
                                  card,
                                  false
                                );
                              } else {
                                this.activate(
                                  e.target.closest(".card"),
                                  card,
                                  popup.type
                                );
                              }
                            }}
                            showFlag={showFlags}
                            ref={`popup-${card.id}`}
                            {...card}
                          >
                            {popup.type === "search"
                              ? (
                                  this.getHolderDescription(card.slot) ||
                                  this.getSlotDescription(card.slot).reverse()
                                ).map((text, idx) => (
                                  <div
                                    className="result-description"
                                    data-tip={text}
                                    key={idx}
                                    slot={idx === 0 ? "top" : "bottom"}
                                  >
                                    {text}
                                  </div>
                                ))
                              : null}
                            {popup.type === "slot-card" ? (
                              <div className="move-card">
                                <button
                                  disabled={idx === 0}
                                  onClick={() =>
                                    this.swap(cards[idx], cards[idx - 1])
                                  }
                                >
                                  <i className="far fa-chevron-left" />
                                </button>
                                <button
                                  disabled={idx === cards.length - 1}
                                  onClick={() =>
                                    this.swap(cards[idx], cards[idx + 1])
                                  }
                                >
                                  <i className="far fa-chevron-right" />
                                </button>
                              </div>
                            ) : null}
                          </DraggableCard>
                        ))}
                      </div>
                    </div>
                  </div>
                ))(
                  popup
                    .cards()
                    .filter((card) => !popup.flag || card.flag === popup.flag)
                )}
                <div
                  className="backdrop"
                  onClick={() => this.setState({ popup: null, selected: {} })}
                />
              </React.Fragment>
            ) : null}

            {showAdminSettings && role === "admin" ? (
              <div className="settings">
                <div className="settings-title">
                  <div className="settings-tabs">
                    <a
                      className={cn({
                        active: showAdminSettings === "general",
                      })}
                      onClick={() =>
                        this.setState({ showAdminSettings: "general" })
                      }
                    >
                      Instellingen
                    </a>
                    <a
                      className={cn({ active: showAdminSettings === "cards" })}
                      onClick={() =>
                        this.setState({ showAdminSettings: "cards" })
                      }
                    >
                      Kaartenbakken
                    </a>
                    {showHeaders && type === "fixed" ? (
                      <a
                        className={cn({
                          active: showAdminSettings === "headers",
                        })}
                        onClick={() =>
                          this.setState({ showAdminSettings: "headers" })
                        }
                      >
                        Kolomkoppen
                      </a>
                    ) : null}
                    {showSlotLabels ? (
                      <a
                        className={cn({
                          active: showAdminSettings === "slots",
                        })}
                        onClick={() =>
                          this.setState({ showAdminSettings: "slots" })
                        }
                      >
                        Slotlabels
                      </a>
                    ) : null}
                    {showFlags ? (
                      <a
                        className={cn({
                          active: showAdminSettings === "flags",
                        })}
                        onClick={() =>
                          this.setState({ showAdminSettings: "flags" })
                        }
                      >
                        Markeringen
                      </a>
                    ) : null}
                    <a
                      className={cn({
                        active: showAdminSettings === "backups",
                      })}
                      onClick={() =>
                        this.setState({ showAdminSettings: "backups" })
                      }
                    >
                      Backups
                    </a>
                    <a
                      className={cn({
                        active: showAdminSettings === "connections",
                      })}
                      onClick={() =>
                        this.setState({ showAdminSettings: "connections" })
                      }
                    >
                      Actieve gebruikers
                    </a>
                    <a
                      className={cn({ active: showAdminSettings === "log" })}
                      onClick={() =>
                        this.setState(
                          { showAdminSettings: "log", log: [] },
                          this.getLog
                        )
                      }
                    >
                      Log
                    </a>
                  </div>
                  <button
                    className="settings-close"
                    onClick={() =>
                      this.setState(
                        { showAdminSettings: false },
                        this.saveSettings
                      )
                    }
                  >
                    <i className="far fa-times" />
                  </button>
                </div>
                <div className="settings-body" key={showAdminSettings}>
                  {showAdminSettings === "general" && (
                    <>
                      <div className="field">
                        <label htmlFor="adminPincode">Pincode voor admin</label>
                        <div className="input">
                          <input
                            type="text"
                            defaultValue={board.adminPincode}
                            onBlur={(e) => {
                              board.adminPincode = e.target.value;
                              this.setState({ board });
                            }}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="pincode">Pincode voor gebruiker</label>
                        <div className="input">
                          <input
                            type="text"
                            defaultValue={board.userPincode}
                            onBlur={(e) => {
                              board.userPincode = e.target.value;
                              this.setState({ board });
                            }}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="pincode">Pincode voor lezen</label>
                        <div className="input">
                          <input
                            type="text"
                            defaultValue={board.readerPincode}
                            onBlur={(e) => {
                              board.readerPincode = e.target.value;
                              this.setState({ board });
                            }}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="type">Type planbord</label>
                        <div className="input">
                          <select
                            value={type}
                            id="type"
                            name="settings.type"
                            onChange={this.handleSettingsChange}
                          >
                            <option value="continuous">Doorlopend</option>
                            <option value="fixed">Vast</option>
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="alignment">Tekst uitlijning</label>
                        <div className="input">
                          <select
                            name="settings.alignment"
                            id="alignment"
                            value={alignment}
                            onChange={this.handleSettingsChange}
                          >
                            <option value="center">Midden</option>
                            <option value="left">Links</option>
                            <option value="right">Rechts</option>
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="multiple">
                          Meerdere kaarten in 1 slot
                        </label>
                        <div className="input checkbox">
                          <input
                            type="checkbox"
                            id="multiple"
                            checked={multiple}
                            onChange={this.handleSettingsChange}
                            name="settings.multiple"
                          />
                          <label htmlFor="multiple">
                            <span />
                          </label>
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="type">Snelle kaart kiezer</label>
                        <div className="input checkbox">
                          <input
                            type="checkbox"
                            id="cardPicker"
                            checked={settings.cardPicker}
                            onChange={this.handleSettingsChange}
                            name="settings.cardPicker"
                          />
                          <label htmlFor="cardPicker">
                            <span />
                          </label>
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="type">Gebruik markering</label>
                        <div className="input checkbox">
                          <input
                            type="checkbox"
                            id="showFlags"
                            checked={showFlags}
                            onChange={this.handleSettingsChange}
                            name="settings.showFlags"
                          />
                          <label htmlFor="showFlags">
                            <span />
                          </label>
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="type">
                          Tekst automatisch verkleinen
                        </label>
                        <div className="input checkbox">
                          <input
                            type="checkbox"
                            id="resizeEnabled"
                            checked={resizeEnabled}
                            onChange={this.handleSettingsChange}
                            name="settings.resizeEnabled"
                          />
                          <label htmlFor="resizeEnabled">
                            <span />
                          </label>
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="slots">Aantal slots</label>
                        <div className="input">
                          <input
                            type="number"
                            id="slots"
                            name="settings.slots"
                            defaultValue={slots}
                            onBlur={this.handleSettingsChange}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="numCardTypes">Aantal kaartbakken</label>
                        <div className="input">
                          <input
                            type="number"
                            id="numCardTypes"
                            name="cardTypes"
                            defaultValue={cardTypes.length}
                            onBlur={this.handleEditCardTypes}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="numCardHolders">
                          Aantal flexbakken
                        </label>
                        <div className="input">
                          <input
                            type="number"
                            id="numCardHolders"
                            name="cardHolders"
                            defaultValue={cardHolders.length}
                            onBlur={this.handleEditCardHolders}
                          />
                        </div>
                      </div>
                      {cardHolders.length > 0 ? (
                        <div className="field">
                          <label htmlFor="cardHolderSort">
                            Sorteren flexbak
                          </label>
                          <div className="input">
                            <select
                              value={cardHolderSort}
                              id="cardHolderSort"
                              name="settings.cardHolderSort"
                              onChange={this.handleSettingsChange}
                            >
                              <option value="">-</option>
                              {new Array(titleLines).fill().map((_, i) => (
                                <option key={i} value={`title.${i}`}>
                                  {i + 1}e regel
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ) : null}
                      {type === "fixed" ? (
                        <>
                          <div className="field">
                            <label htmlFor="columns">Aantal kolommen</label>
                            <div className="input">
                              <input
                                type="number"
                                id="columns"
                                name="settings.columns"
                                defaultValue={columns}
                                onBlur={this.handleSettingsChange}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor="showHeaders">
                              Toon kolomkoppen
                            </label>
                            <div className="input checkbox">
                              <input
                                type="checkbox"
                                id="showHeaders"
                                checked={showHeaders}
                                onChange={this.handleSettingsChange}
                                name="settings.showHeaders"
                              />
                              <label htmlFor="showHeaders">
                                <span />
                              </label>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="field">
                        <label htmlFor="columns">Toon slotlabels</label>
                        <div className="input checkbox">
                          <input
                            type="checkbox"
                            id="showSlotLabels"
                            checked={showSlotLabels}
                            onChange={this.handleSettingsChange}
                            name="settings.showSlotLabels"
                          />
                          <label htmlFor="showSlotLabels">
                            <span />
                          </label>
                        </div>
                      </div>
                      {type === "continuous" ? (
                        <>
                          <div className="field">
                            <label htmlFor="weeks">Aantal weken tonen</label>
                            <div className="input">
                              <input
                                type="number"
                                id="weeks"
                                name="settings.weeks"
                                defaultValue={settings.weeks}
                                placeholder={settings.weeks}
                                onBlur={this.handleSettingsChange}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor="slots">Dagen tonen</label>
                            <div className="input checkboxes">
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="monday"
                                  checked={days.indexOf(0) !== -1}
                                  value={0}
                                  onChange={this.handleSettingsChange}
                                  name="settings.days"
                                />
                                <label htmlFor="monday">
                                  <span />
                                  maandag
                                </label>
                              </div>
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="tuesday"
                                  checked={days.indexOf(1) !== -1}
                                  value={1}
                                  onChange={this.handleSettingsChange}
                                  name="settings.days"
                                />
                                <label htmlFor="tuesday">
                                  <span />
                                  dinsdag
                                </label>
                              </div>
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="wednesday"
                                  checked={days.indexOf(2) !== -1}
                                  value={2}
                                  onChange={this.handleSettingsChange}
                                  name="settings.days"
                                />
                                <label htmlFor="wednesday">
                                  <span />
                                  woensdag
                                </label>
                              </div>
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="thursday"
                                  checked={days.indexOf(3) !== -1}
                                  value={3}
                                  onChange={this.handleSettingsChange}
                                  name="settings.days"
                                />
                                <label htmlFor="thursday">
                                  <span />
                                  donderdag
                                </label>
                              </div>
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="friday"
                                  checked={days.indexOf(4) !== -1}
                                  value={4}
                                  onChange={this.handleSettingsChange}
                                  name="settings.days"
                                />
                                <label htmlFor="friday">
                                  <span />
                                  vrijdag
                                </label>
                              </div>
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="saturday"
                                  checked={days.indexOf(5) !== -1}
                                  value={5}
                                  onChange={this.handleSettingsChange}
                                  name="settings.days"
                                />
                                <label htmlFor="saturday">
                                  <span />
                                  zaterdag
                                </label>
                              </div>
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="sunday"
                                  checked={days.indexOf(6) !== -1}
                                  value={6}
                                  onChange={this.handleSettingsChange}
                                  name="settings.days"
                                />
                                <label htmlFor="sunday">
                                  <span />
                                  zondag
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
                  {showAdminSettings === "cards" && (
                    <>
                      <div className="field">
                        <label htmlFor="titleLines">Aantal titel regels</label>
                        <div className="input">
                          <input
                            type="number"
                            id="titleLines"
                            name="settings.titleLines"
                            defaultValue={titleLines}
                            onBlur={this.handleSettingsChange}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="cardWidth">Breedte kaartenbak</label>
                        <div className="input">
                          <input
                            type="number"
                            id="cardWidth"
                            name="settings.cardWidth"
                            defaultValue={settings.cardWidth}
                            placeholder={settings.cardWidth}
                            onBlur={this.handleSettingsChange}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="cardHeight">Hoogte kaartenbak</label>
                        <div className="input">
                          <input
                            type="number"
                            id="cardHeight"
                            name="settings.cardHeight"
                            defaultValue={settings.cardHeight}
                            placeholder={settings.cardHeight}
                            onBlur={this.handleSettingsChange}
                          />
                        </div>
                      </div>
                      {cardTypes.map((cardType, idx) => (
                        <div key={cardType.id}>
                          <div className="settings-header">
                            <strong>Kaartenbak {idx + 1}</strong>
                          </div>
                          <div className="field">
                            <label htmlFor={`${cardType.id}-description`}>
                              Naam
                            </label>
                            <div className="input">
                              <input
                                type="text"
                                id={`${cardType.id}-description`}
                                defaultValue={cardType.description}
                                onBlur={(e) => {
                                  cardType.description = e.target.value;
                                  this.setState({ board });
                                }}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor={`${cardType.id}-color`}>
                              Kleur
                            </label>
                            <div className="input">
                              <input
                                type="color"
                                id={`${cardType.id}-name`}
                                defaultValue={cardType.color}
                                onBlur={(e) => {
                                  cardType.color = e.target.value;
                                  this.setState({ board });
                                }}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor={`${cardType.id}-title`}>
                              Standaard titel
                              <span className="help">
                                ; voor meerdere regels
                              </span>
                            </label>
                            <div className="input">
                              <input
                                type="text"
                                id={`${cardType.id}-title`}
                                defaultValue={cardType.defaultTitle}
                                onBlur={(e) => {
                                  cardType.defaultTitle = e.target.value;
                                  this.setState({ board });
                                }}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor={`${cardType.id}-body`}>
                              Standaard inhoud
                            </label>
                            <div className="input">
                              <input
                                type="text"
                                id={`${cardType.id}-body`}
                                defaultValue={cardType.defaultBody}
                                onBlur={(e) => {
                                  cardType.defaultBody = e.target.value;
                                  this.setState({ board });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      {cardHolders.length ? (
                        <div className="settings-header">
                          <strong>Flexbakken</strong>
                        </div>
                      ) : null}
                      {cardHolders.map((cardHolder, idx) => (
                        <div key={cardHolder.id}>
                          <div className="field">
                            <label htmlFor={`${cardHolder.id}-description`}>
                              Omschrijving {idx + 1}
                            </label>
                            <div className="input">
                              <input
                                type="text"
                                id={`${cardHolder.id}-description`}
                                defaultValue={cardHolder.description}
                                onBlur={(e) => {
                                  cardHolder.description = e.target.value;
                                  this.setState({ board });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {showAdminSettings === "headers" && (
                    <>
                      {new Array(columns).fill().map((_, idx) => (
                        <div className="field" key={idx}>
                          <label>Kolomkop {idx + 1}</label>
                          <div className="input">
                            <input
                              type="text"
                              defaultValue={
                                headers[idx] === undefined
                                  ? idx + 1
                                  : headers[idx]
                              }
                              onBlur={(e) => {
                                settings.headers[idx] = e.target.value;
                                this.setState({ board });
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {showAdminSettings === "slots" && (
                    <>
                      {new Array(slots).fill().map((_, idx) => (
                        <div className="field" key={idx}>
                          <label>Slotlabel {idx + 1}</label>
                          <div className="input">
                            <input
                              type="text"
                              defaultValue={slotLabels[idx] || ""}
                              onBlur={(e) => {
                                settings.slotLabels = settings.slotLabels || [];
                                settings.slotLabels[idx] = e.target.value;
                                this.setState({ board });
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {showAdminSettings === "flags" && (
                    <>
                      <div className="field">
                        <label htmlFor="flags">Aantal markeringen</label>
                        <div className="input">
                          <input
                            type="number"
                            id="flags"
                            name="settings.flags"
                            defaultValue={flags.length}
                            onBlur={this.handleEditFlags}
                          />
                        </div>
                      </div>
                      {flags.map((flag, idx) => (
                        <div className="field" key={idx}>
                          <label>Markering {idx + 1}</label>
                          <div className="input">
                            <input
                              type="color"
                              defaultValue={flag}
                              onBlur={(e) => {
                                flags[idx] = e.target.value;
                                settings.flags = flags;
                                this.setState({ board });
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {showAdminSettings === "backups" && (
                    <>
                      <div className="field">
                        <label htmlFor="backup">
                          Dagelijkse backups (om 03:00)
                        </label>
                        <div className="input checkbox">
                          <input
                            type="checkbox"
                            id="backup"
                            checked={backup}
                            onChange={this.handleSettingsChange}
                            name="settings.backup"
                          />
                          <label htmlFor="backup">
                            <span />
                          </label>
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="backupEmail">Backup e-mailadres</label>
                        <div className="input">
                          <input
                            type="text"
                            id="backupEmail"
                            defaultValue={backupEmail}
                            onBlur={this.handleSettingsChange}
                            name="settings.backupEmail"
                          />
                        </div>
                      </div>
                      <div className="settings-header">
                        <strong>Backups</strong>
                        <button type="button" onClick={this.backup}>
                          <i className="far fa-plus" />
                        </button>
                      </div>
                      <div className="backups">
                        {backups.map((backup) => (
                          <div className="file" key={backup.name}>
                            <div className="name">{backup.name}</div>
                            <div className="size">{filesize(backup.size)}</div>
                            <div>
                              <button
                                type="button"
                                onClick={() =>
                                  this.requestDownload(backup.name)
                                }
                              >
                                Download
                              </button>
                              <button
                                type="button"
                                className="text-danger"
                                onClick={() => this.removeBackup(backup.name)}
                                style={{ marginLeft: 8 }}
                              >
                                <i className="far fa-trash-alt" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="settings-header">
                        <strong>Importeer backup</strong>
                      </div>
                      <div className="field" style={{ alignItems: "center" }}>
                        <div>
                          <input type="file" id="import" />
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <button type="button" onClick={this.import}>
                            Importeer
                          </button>
                        </div>
                      </div>
                      {type === "continuous" && (
                        <>
                          <div className="settings-header">
                            <strong>Database opschonen</strong>
                          </div>
                          <div className="field">
                            <label htmlFor="from">Vanaf</label>
                            <div className="input">
                              <input type="date" id="from" />
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor="to">Tot en met</label>
                            <div className="input">
                              <input type="date" id="to" />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              type="button"
                              className="button-danger"
                              onClick={() => this.clean()}
                            >
                              Opschonen
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {showAdminSettings === "connections" && (
                    <>
                      {connections.map(
                        ({ id, ip, os, role, ...browser }, idx) => (
                          <div className="connection" key={idx}>
                            <div className="connection-idx">{idx + 1}.</div>
                            <div>
                              <div className="connection-os">{os.family}</div>
                              <div className="connection-browser">
                                {browser.family}
                              </div>
                              <div className="connection-ip">
                                {ip.substr(0, 7) === "::ffff:"
                                  ? ip.substr(7)
                                  : ip}
                              </div>
                              <div className="connection-id">{id}</div>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                              <span className="badge">{role}</span>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  )}
                  {showAdminSettings === "log" &&
                    log.map((line, idx) => (
                      <div className="log" key={idx}>
                        <div className="log-time">{line.time}</div>
                        <div className="log-action">{line.action}</div>
                        <div className="log-id">{line.id}</div>
                      </div>
                    ))}
                </div>
                <div className="settings-footer">
                  <button
                    type="button"
                    className="text-danger"
                    onClick={this.resetSettings}
                  >
                    Resetten
                  </button>
                  <button
                    type="button"
                    className="button-primary"
                    onClick={() =>
                      this.setState(
                        { showAdminSettings: false },
                        this.saveSettings
                      )
                    }
                  >
                    Opslaan
                  </button>
                </div>
              </div>
            ) : null}
            {showAdminSettings && role === "admin" ? (
              <div
                className="backdrop"
                onClick={() =>
                  this.setState({ showAdminSettings: false }, this.saveSettings)
                }
              />
            ) : null}
            {showSettings ? (
              <div className="settings">
                <div className="settings-title">
                  <div className="settings-tabs">
                    <a
                      className={cn({ active: showSettings === "general" })}
                      onClick={() => this.setState({ showSettings: "general" })}
                    >
                      Voorkeuren
                    </a>
                  </div>
                  <button
                    className="settings-close"
                    onClick={() =>
                      this.setState(
                        { showSettings: false },
                        this.saveLocalSettings
                      )
                    }
                  >
                    <i className="far fa-times" />
                  </button>
                </div>
                <div className="settings-body">
                  {type === "continuous" && !isApp ? (
                    <>
                      <div className="field">
                        <label htmlFor="weeks">Aantal weken tonen</label>
                        <div className="input">
                          <input
                            type="number"
                            id="weeks"
                            name="local.weeks"
                            defaultValue={local.weeks}
                            placeholder={settings.weeks}
                            onBlur={this.handleSettingsChange}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {!isApp ? (
                    <>
                      <div className="field">
                        <label htmlFor="cardWidth">Breedte kaartenbak</label>
                        <div className="input">
                          <input
                            type="number"
                            id="cardWidth"
                            name="local.cardWidth"
                            defaultValue={local.cardWidth}
                            placeholder={settings.cardWidth || 150}
                            onBlur={this.handleSettingsChange}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="cardHeight">Hoogte kaartenbak</label>
                        <div className="input">
                          <input
                            type="number"
                            id="cardHeight"
                            name="local.cardHeight"
                            defaultValue={local.cardHeight}
                            placeholder={settings.cardHeight || 150}
                            onBlur={this.handleSettingsChange}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {role !== "reader" ? (
                    <>
                      {!isApp ? (
                        <>
                          <div className="field">
                            <label htmlFor="type">Snelle kaart kiezer</label>
                            <div className="input checkbox">
                              <input
                                type="checkbox"
                                id="cardPicker"
                                checked={local.cardPicker}
                                onChange={this.handleSettingsChange}
                                name="local.cardPicker"
                              />
                              <label htmlFor="cardPicker">
                                <span />
                              </label>
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor="autoOpen">
                              Lege kaart automatisch openen
                            </label>
                            <div className="input checkbox">
                              <input
                                type="checkbox"
                                id="autoOpen"
                                checked={local.autoOpen}
                                onChange={this.handleSettingsChange}
                                name="local.autoOpen"
                              />
                              <label htmlFor="autoOpen">
                                <span />
                              </label>
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor="autoExpanded">
                              Kaart automatisch vergroten
                            </label>
                            <div className="input checkbox">
                              <input
                                type="checkbox"
                                id="autoExpanded"
                                checked={local.autoExpanded}
                                onChange={this.handleSettingsChange}
                                name="local.autoExpanded"
                              />
                              <label htmlFor="autoExpanded">
                                <span />
                              </label>
                            </div>
                          </div>
                          <div className="field">
                            <label htmlFor="defaultPopupSize">
                              Grootte flexbak
                            </label>
                            <div className="input">
                              <select
                                value={local.defaultPopupSize}
                                id="defaultPopupSize"
                                name="local.defaultPopupSize"
                                onChange={this.handleSettingsChange}
                              >
                                <option value="default">Normaal</option>
                                <option value="full">Volledig</option>
                              </select>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
                <div className="settings-footer">
                  <div>
                    <button
                      type="button"
                      className="text-danger"
                      style={{ marginRight: 5 }}
                      onClick={this.resetLocalSettings}
                    >
                      Resetten
                    </button>
                    <button
                      type="button"
                      className="text-primary"
                      onClick={this.logout}
                    >
                      Uitloggen
                    </button>
                  </div>
                  <button
                    type="button"
                    className="button-primary"
                    onClick={() =>
                      this.setState(
                        { showSettings: false },
                        this.saveLocalSettings
                      )
                    }
                  >
                    Opslaan
                  </button>
                </div>
              </div>
            ) : null}
            {showSettings ? (
              <div
                className="backdrop"
                onClick={() =>
                  this.setState({ showSettings: false }, this.saveLocalSettings)
                }
              />
            ) : null}

            <div className="board-buttons">
              <button
                onClick={this.showSearch}
                data-tip="Zoeken <kbd>CTRL</kbd><span style='margin: 0 .125rem'>+</span><kbd>F</kbd>"
                data-html
              >
                <i className="far fa-search" />
              </button>
              {!isApp ? (
                <button
                  onClick={() => this.setState({ showSettings: "general" })}
                  data-tip="Voorkeuren"
                >
                  <i className="far fa-user-cog" />
                </button>
              ) : null}
              {role === "admin" && !isApp ? (
                <button
                  onClick={() =>
                    this.setState({ showAdminSettings: "general" })
                  }
                  data-tip="Instellingen"
                >
                  <i className="far fa-cog" />
                </button>
              ) : null}
            </div>

            {active ? (
              <>
                <div
                  className={cn("active-card", {
                    expanded: isApp || active.expanded,
                  })}
                  ref="active-card"
                >
                  {active.card.slot && (
                    <div className="slot-description">
                      {this.getSlotDescription(active.card.slot).map(
                        (description, idx) => (
                          <div
                            key={idx}
                            style={{ background: active.card.color }}
                          >
                            {description}
                          </div>
                        )
                      )}
                    </div>
                  )}
                  <Card
                    {...active.card}
                    style={active.position}
                    editable={role !== "reader"}
                    numTitleLines={titleLines}
                    onChange={() => {
                      active.hasChanged = true;
                      this.setState({ active });
                    }}
                    onSave={(data) => {
                      if (this.handleOnChange(active.card, data)) {
                        active.hasChanged = true;
                      }
                    }}
                  >
                    <div className="card-actions">
                      {this.getActionsForType(active.type).map((item, idx) =>
                        item === "spacer" ? (
                          <div style={{ margin: "0 auto" }} key={idx} />
                        ) : (item.roles && item.roles.includes(role)) ||
                          !item.roles ? (
                          <button
                            onClick={item.onClick}
                            data-tip={item.title}
                            key={idx}
                            className={item.className || ""}
                            style={item.style}
                          >
                            <i className={item.icon} />
                          </button>
                        ) : null
                      )}
                    </div>
                    {active.showFlagPicker ? (
                      <CardPicker onOutsideClick={this.toggleFlagPicker}>
                        {flags.map((flag) => (
                          <div
                            key={flag}
                            className={cn("flag", {
                              active: flag === active.card.flag,
                            })}
                            style={{ color: flag, borderColor: flag }}
                            onClick={() => {
                              this.handleOnChange(active.card, {
                                flag: flag === active.card.flag ? null : flag,
                              });
                              this.toggleFlagPicker();
                              this.writeLog(
                                `${this.logCardTitle(
                                  active.card
                                )} markering toegevoegd`
                              );
                            }}
                          />
                        ))}
                      </CardPicker>
                    ) : null}
                    {active.showCardPicker ? (
                      <CardPicker onOutsideClick={this.toggleCardPicker}>
                        {cardTypes.map((type, idx) => (
                          <div
                            key={type.id}
                            className={cn("card-type", {
                              active: type.id === active.card.cardTypeId,
                            })}
                            style={{ backgroundColor: type.color }}
                            onClick={() => {
                              this.handleOnChange(active.card, {
                                color: type.color,
                                cardTypeId: type.id,
                              });
                              this.toggleCardPicker();
                              this.writeLog(
                                `${this.logCardTitle(
                                  active.card
                                )} kleur veranderd`
                              );
                            }}
                          />
                        ))}
                      </CardPicker>
                    ) : null}
                  </Card>
                </div>
                <div className="backdrop" onClick={this.deactivate} />
              </>
            ) : null}
          </DndProvider>
        )}
      </div>
    );
  };
}

export default Board;
