import React from 'react'
import cn from 'classnames'
import { DropTarget } from 'react-dnd'

const cardHolderTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return
    }
    const item = monitor.getItem()
    props.onDropped(item)
    return { moved: false }
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    over: monitor.isOver(),
    itemType: monitor.getItemType()
  }
}

class CardHolder extends React.Component {

  render() {
    const { children, over, connectDropTarget, className, expanded, onDropped, ...props } = this.props

    return connectDropTarget(
      <div className={cn("card-type card-holder", className, { over, expanded })} {...props}>
        {children}
      </div>
    )
  }
}

export default DropTarget('card', cardHolderTarget, collect)(CardHolder)