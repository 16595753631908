import React from 'react'
import Board from './Board'

const getDirectoryFromPath = () => {
  const path = window.location.pathname
  let directory = path === '/' ? 'demo' : path
  if (directory.startsWith('/')) {
    directory = directory.substr(1)
  }
  return directory
}

const getDirectoryName = (dir) => {
  dir = dir.replace(new RegExp('/', 'g'), '-').toLowerCase()
  dir = dir.split('-').filter(part => part).join('-')
  return dir
}

class App extends React.Component {
  state = {
    key: Math.random(),
    directory: window.cordova ? localStorage.getItem('directory') || 'demo' : getDirectoryFromPath(),
  }

  componentDidMount() {
    this.changeTitle()
  }
  
  componentDidUpdate() {
    this.changeTitle()
  }
  
  changeTitle() {
    const { directory } = this.state
    document.title = `T-Card planning ${directory} | Ster Software B.V.`
  }

  changeDirectory(directory) {
    this.setState({ directory }, () => {
      localStorage.setItem('directory', directory)
    })
  }

  render() {
    const { directory, key } = this.state
    return <Board
      key={key}
      logout={() => this.setState({ key: Math.random() })}
      directory={getDirectoryName(directory)}
      changeDirectory={window.cordova ? (directory) => this.changeDirectory(directory) : null}
    />
  }
}

export default App;
