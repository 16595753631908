import React from 'react'


export default class CardPicker extends React.Component {
  constructor(props) {
    super(props)
    this.click = this.click.bind(this)
  }

  componentDidMount() {
    setTimeout(() => window.addEventListener('click', this.click))
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.click)
  }

  click(e) {
    if (!this.refs.el.contains(e.target) && this.refs.el !== e.target) {
      this.props.onOutsideClick()
    }
  }

  render() {
    return <div className="card-picker" ref='el'>{this.props.children}</div>
  }
}