import React from 'react'
import ReactDOM from 'react-dom'
import '@fortawesome/fontawesome-pro/css/all.min.css'
import './index.scss'
import App from './App'

const initApp = () => {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  )
}

if (!!window.cordova){
  document.addEventListener("deviceready", () => initApp(), false)
} else {
  initApp()
}
