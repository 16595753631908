import React from 'react'
import cn from 'classnames'
import { DragLayer } from 'react-dnd'

function createHorizontalStrength(_buffer) {
  return function defaultHorizontalStrength({ x, w, y, h }, point) {
    const buffer = Math.min(w / 2, _buffer)
    const inRange = point.x >= x && point.x <= x + w
    const inBox = inRange && point.y >= y && point.y <= y + h

    if (inBox) {
      if (point.x < x + buffer) {
        return (point.x - x - buffer) / buffer
      } else if (point.x > (x + w - buffer)) {
        return -(x + w - point.x - buffer) / buffer
      }
    }

    return 0
  }
}

function createVerticalStrength(_buffer) {
  return function defaultVerticalStrength({ y, h, x, w }, point) {
    const buffer = Math.min(h / 2, _buffer)
    const inRange = point.y >= y && point.y <= y + h
    const inBox = inRange && point.x >= x && point.x <= x + w

    if (inBox) {
      if (point.y < y + buffer) {
        return (point.y - y - buffer) / buffer
      } else if (point.y > (y + h - buffer)) {
        return -(y + h - point.y - buffer) / buffer
      }
    }

    return 0
  }
}

const verticalStrength = createVerticalStrength(100)
const horizontalStrength = createHorizontalStrength(200)

function getCoords(evt) {
  if (evt.type === 'touchmove') {
    return { x: evt.changedTouches[0].clientX, y: evt.changedTouches[0].clientY }
  }

  return { x: evt.clientX, y: evt.clientY }
}

function intBetween(min, max, val) {
  return Math.floor(Math.min(max, Math.max(min, val)))
}

class WeeksLayer extends React.Component {
  scroll(e) {
    const { el } = this.refs
    const shouldStick = el.scrollTop > 0

    if (shouldStick) {
      el.classList.add("sticky-headers")
    } else if (!shouldStick) {
      el.classList.remove("sticky-headers")
    }
  }

  hover(e) {
    const { el } = this.refs
    const { left, top, width, height } = el.getBoundingClientRect()
    const box = { x: left, y: top, w: width, h: height }
    const coords = (0, getCoords)(e)

    this._scaleX = horizontalStrength(box, coords)
    this._scaleY = verticalStrength(box, coords)
  }

  doScroll() {
    const { el } = this.refs

    const scrollLeft = el.scrollLeft,
    scrollTop = el.scrollTop,
    scrollWidth = el.scrollWidth,
    scrollHeight = el.scrollHeight,
    clientWidth = el.clientWidth,
    clientHeight = el.clientHeight

    if (this._scaleX) {
      el.scrollLeft = (0, intBetween)(0, scrollWidth - clientWidth, scrollLeft + this._scaleX * 10)
    }

    if (this._scaleY) {
      el.scrollTop = (0, intBetween)(0, scrollHeight - clientHeight, scrollTop + this._scaleY * 5)
    }
  }

  componentDidUpdate() {
    if (this.props.isDragging) {
      this.start()
    } else {
      this.stop()
    }
  }

  start() {
    this.stop()
    this._interval = setTimeout(() => {
      this._interval = setInterval(this.doScroll.bind(this))
    }, 1000)
  }

  stop() {
    clearInterval(this._interval)
  }
    
  render() {
    const { children } = this.props
    return <div
      className={cn("weeks")}
      ref="el"
      onMouseOut={() => {
        this._scaleX = 0
        this._scaleY = 0
      }}
      onMouseOver={e => this.hover(e)}
      onTouchMove={e => this.hover(e)}
      onScroll={e => this.scroll(e)}
    >{children}</div>
  }
}

function collect(monitor) {
  return {
    isDragging: monitor.isDragging()
  }
}
  
export default DragLayer(collect)(WeeksLayer)
