import React from 'react'
import cn from 'classnames'
import { DropTarget } from 'react-dnd'

const slotTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return
    }
    const item = monitor.getItem()
    props.onDropped(item)
    return { moved: false }
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    over: monitor.isOver(),
    itemType: monitor.getItemType(),
  }
}

class Slot extends React.Component {

  render() {
    const { children, multiple, over, connectDropTarget, className, onDropped, ...props } = this.props
    return connectDropTarget(
      <div className={cn("slot", className, { over, multiple })} {...props}>
        {children}
      </div>
    )
  }
}

export default DropTarget('card', slotTarget, collect)(Slot)