import { nanoid } from 'nanoid'

export const LOCAL_SETTINGS = {
  cardPicker: false,
  autoExpanded: false,
  autoOpen: false,
  defaultPopupSize: 'normal',
}

export const ONLINE_SETTINGS = {
  type: 'continuous',
  backup: false,
  backupEmail: '',
  weeks: 4,
  cardWidth: 150,
  cardHeight: 150,
  titleLines: 1,
  days: [0, 1, 2, 3, 4],
  alignment: "center",
  slots: 12,
  columns: 12,
  headers: [],
  flags: [
    '#C3423F',
    '#FFA62B',
    '#FDE74C',
    '#7CB518',
    '#3C91E6',
  ],
  showHeaders: true,
  showFlags: false,
  resizeEnabled: true,
  slotLabels: [],
  showSlotLabels: false,
  multiple: false,
  cardHolderSort: 'title.0',
  cardHolderName: 'Lege bak',
  cardHolders: [],
  cardTypes: [
    {
      id: nanoid(),
      description: 'Rood',
      stack: [],
      color: '#C3423F',
      defaultTitle: '',
      defaultBody: '',
    },
    {
      id: nanoid(),
      description: 'Oranje',
      stack: [],
      color: '#FFA62B',
      defaultTitle: '',
      defaultBody: '',
    },
    {
      id: nanoid(),
      description: 'Geel',
      stack: [],
      color: '#FDE74C',
      defaultTitle: '',
      defaultBody: '',
    },
    {
      id: nanoid(),
      description: 'Groen',
      stack: [],
      color: '#7CB518',
      defaultTitle: '',
      defaultBody: '',
    },
    {
      id: nanoid(),
      description: 'Blauw',
      stack: [],
      color: '#3C91E6',
      defaultTitle: '',
      defaultBody: '',
    },
  ]
}
