import React from 'react'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'
import _ from 'lodash'
import TextFill from './TextFill'

const isDark = color => {
  const rgb = parseInt(color.substr(1), 16)
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = (rgb >> 0) & 0xff
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b
  return luma < 75
}

const addSplitter = (s) => s.split('|').map((part, idx) => <span key={idx}>{part}</span>)

export class Card extends React.Component {
  state = {
    title: _.clone(Array.isArray(this.props.title) ? this.props.title : [this.props.title]),
    body: _.clone(this.props.body),
    updated: false,
  }

  constructor(props) {
    super(props)
    this.save = this.save.bind(this)
    this.change = this.change.bind(this)
  }

  save(e) {
    const { onSave } = this.props
    const { title, body } = this.state
    if (onSave) {
      onSave({ title, body })
    }
  }

  change(e) {
    const { onChange } = this.props
    const { name, value } = e.target
    this.setState(_.set(this.state, name, value))

    if (onChange) {
      onChange(name, value)
    }
  }

  componentDidCatch(JSError, JSErrorInfo) {
    this.setState({ JSError, JSErrorInfo })
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.title, this.props.title)) {
      this.setState({ title: _.clone(this.props.title) })
    }
    if (!_.isEqual(prevProps.body, this.props.body)) {
      this.setState({ body: _.clone(this.props.body) })
    }
    if (!this.props.updating && prevProps.updating) {
      this.setState({ updated: true })
      setTimeout(() => this.setState({ updated: false }), 60 * 1000)
    }
  }

  render() {
    const { title, body, updated } = this.state
    let {
      preview,
      color,
      editable,
      disabled,
      dragging,
      selected,
      updating,
      style,
      children,
      inputProps,
      numTitleLines,
      multiple,
      onClick,
      flag,
      showFlag,
      resizeEnabled,
    } = this.props

    style = {
      '--card-bg-color': color,
      ...style,
    }

    const linesArray = new Array(numTitleLines).fill('')

    return (
      <>
      <div className={cn("card", { updating, updated, dragging, multiple, preview, disabled, selected, dark: isDark(color) })} style={style} onClick={disabled ? null : onClick} data-tip={disabled ? "Kaart is ingebruik" : null}>
        <div className="card-title" style={body && body.length ? { fontWeight: 'bold' } : {}}>
          {editable && !updating ? (
            linesArray.map((_, idx) => (
              <input {...inputProps} type="text" key={idx} value={title[idx] || ''} autoComplete="off" name={`title.${idx}`} onChange={this.change} onBlur={this.save} onKeyDown={e => {
                if (e.keyCode === 13) {
                  const parent = e.target.parentNode.parentNode
                  const elements = parent.querySelectorAll('input,textarea')
                  const index = Array.prototype.indexOf.call(elements, e.target)
                  const next = elements.item(index + 1)
                  if (next) {
                    e.preventDefault()
                    next.focus()
                  }
                }
              }} />
            ))
          ) : (
            linesArray.map((_, idx) => (
              resizeEnabled ? (
                <TextFill key={idx}>
                  {idx === 0 && flag && showFlag ? <i className="flag" style={{ color: flag, border: `2px solid ${flag}` }} /> : null}
                  {addSplitter((this.props.title || [])[idx] || '')}
                </TextFill>
              ) : (
                <div key={idx}>
                  {idx === 0 && flag && showFlag ? <i className="flag" style={{ color: flag, border: `2px solid ${flag}` }} /> : null}
                  {addSplitter((this.props.title || [])[idx] || '')}
                </div>
              )
            ))
          )}
        </div>
        <div className="card-body">
          {editable && !updating ? (
            <textarea {...inputProps} value={body} onChange={this.change} name='body' onBlur={this.save} autoComplete="off" />
          ) : (typeof this.props.body === "string" || !this.props.body ? <p>{this.props.body}</p> : this.props.body)}
          {children}
        </div>
        {/* <div className="card-id">{this.props.id}</div> */}
      </div>
      {disabled ? <ReactTooltip className="tooltip" effect="solid" multiline /> : null}
      </>
    )
  }
}


export default Card