import React from 'react'
import { findDOMNode } from 'react-dom'
import { DragSource } from 'react-dnd'
import Card from './Card'

const cardSource = {
  canDrag(props, monitor) {
    return !props.cantDrag && !props.disabled
  },

  isDragging(props, monitor) {
    return monitor.getItem().id === props.id || props.selected
  },

  beginDrag(props, monitor, component) {
    const [node] = findDOMNode(component).children
    if (document.activeElement) {
      document.activeElement.blur()
    }
    if (props.onBeginDrag && !props.sortable) {
      props.onBeginDrag(node) 
    }
    const top = props.index * props.numTitleLines * 20
    return { id: props.id, top }
  },

  endDrag(props, monitor, component) {
    const didDrop = monitor.didDrop()

    if (props.onEndDrag) {
      props.onEndDrag(didDrop)
    }
    if (!didDrop) {
      return
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    dragging: monitor.isDragging(),
  }
}
  
class DraggableCard extends React.Component {
  render() {
    const { connectDragSource, className, children, onBeginDrag, onEndDrag, style, cardProps, ...props } = this.props
    return connectDragSource(
      <div className={className} style={style}>
        {React.Children.toArray(children).filter((child) => child.props.slot === 'top')}
        <Card {...props} {...cardProps} />
        {React.Children.toArray(children).filter((child) => !child.props.slot || child.props.slot === 'bottom')}
      </div>
    )
  }
}

export default DragSource('card', cardSource, collect)(DraggableCard)
